import React from "react";
import styled from "styled-components";
import {
  TopLine,
  BottomLine,
  Header1White,
  ParagraphSubtitleBlack,
} from "styles/common";
import colors from "styles/common/colors";
import { Link } from "react-router-dom";
import lineBottom from "media/lines/gold-line-bottom-shadow-outside.png";
import lineTop from "media/lines/gold-line-top.png";
import feanorapi from "feanorapi";
import useContactForm from 'hooks/useCotactForm'

const SectionBlueBg = styled.div`
  width: 100%;
  color: #ffffff !important;
  padding-top: 50px;
  padding-bottom: 60px;
  position: relative;
`;

const WrapperPreFooter = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  box-sizing: border-box;
  padding: 0 52px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 0 24px;
    align-items: center;
    justfify-content: center;
  }
`;
const ContainerTexts = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 900px) {
    align-items: center;
    justfify-content: center;
  }
`;

const ContainerRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  @media (max-width: 600px) {
    align-items: center;
    justfify-content: center;
  }
`;

const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0 1 100%;
  margin: auto;

  @media (max-width: 640px) {
    margin-top: 10px;
    flex-direction: column;
  }
`;

const StyledButtonYellowExt = styled(Link)`
  color: black;
  background: ${colors.feanor_gold};
  text-decoration: none;
  font-family: "Hahnschift Light", sans-serif;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 70px;
  font-weight: 600;
  padding: 15px 55px;
  margin-top: 10px;
  @media (max-width: 640px) {
    padding: 7px 50px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    width: 120px;
    height: 35px;
  }
`;
const ContactUs = ({ showLineBottom = true }) => {
  const [ContactForm, openForm, setOpenForm, onCloseForm] = useContactForm();


  return (
    <SectionBlueBg>
      <ContactForm openForm={openForm} onCloseForm={onCloseForm}/>
      <div
        style={{ position: "absolute", top: "0px", left: "0", width: "100%" }}
      >
        <TopLine src={lineBottom} />
      </div>
      <WrapperPreFooter>
        <ContainerTexts>
          <Header1White>¿Quienes somos? </Header1White>
          <ParagraphSubtitleBlack>
            Somos una empresa Incorporada en Panamá, FËANOR CORPORATION S.A.
          </ParagraphSubtitleBlack>
          <ParagraphSubtitleBlack style={{"margin-top": 0}}>
            Contamos con un equipo de profesionales de marcada
            experiencia gestionando recursos en proyectos de negocios basados en
            IT, Seguridad de la Información y FINTECH.
          </ParagraphSubtitleBlack>
        </ContainerTexts>
        {/* <ContainerButtons>
          <StyledButtonYellowExt onClick={() => setOpenForm(true)}>
            Contactanos
          </StyledButtonYellowExt>
        </ContainerButtons> */}
      </WrapperPreFooter>
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          left: "0",
          width: "100%",
        }}
      >
        {showLineBottom && <BottomLine src={lineTop} />}
      </div>
    </SectionBlueBg>
  );
};

export default ContactUs;
