import React, { useState, createContext, useEffect } from "react";

export const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
  const [currencyBtc, setCurrencyBtc] = useState(0);

  const API =
    "https://min-api.cryptocompare.com/data/pricemulti?fsyms=BTC&tsyms=USD&api_key={process.env.REACT_APP_CRYPTOCOMPARE_KEY}";

  const getCurrency = async () => {
    const response = await fetch(API);
    const data = await response.json();

    const dataBTC = data.BTC.USD;
    const feanorPrice = dataBTC + 650;
    setCurrencyBtc(feanorPrice);
  };

  const updateCurrencyValue = value => {
    setCurrencyBtc(value);
  };

  useEffect(() => {
    getCurrency();
    // const interval = setInterval(() => {
    //  getCurrency();
    // }, 60000);
    // return () => clearInterval(interval);
  }, []);

  return (
    <CurrencyContext.Provider
      value={{ price: currencyBtc, updateCurrencyValue }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};
