import React from "react";
import { Paragraph, Center, Subtitle, Bold } from "./styles";
const AsociationContract = () => {
  return (
    <div style={{ color: "black" }} id="asociacion">
      <Center>CONTRATO DE ASOCIACIÓN</Center>
      <Center>ANEXO 1</Center>
      <Paragraph>
        Entre los suscritos a saber, <Bold>BELISARIO CASTILLO SÁENZ,</Bold>{" "}
        varón, panameño, mayor de edad, portador de la cédula de identidad
        personal No. 2-700-1677, quien actúa en nombre y representación de
        <Bold>FEANOR CORPORATION, S.A.,</Bold> sociedad anónima debidamente
        inscrita al Folio No. 155706843, de la Sección de Mercantil del Registro
        Público, debidamente facultado para este acto, quien en adelante se
        denominará
        <Bold>FEÄNOR,</Bold>, y por la otra, y por la otra,
        ________________________, _______________, _______________, mayor de
        edad, portador de la cédula de identidad personal No. __________,
        actuando en nombre y representación de _________________, sociedad
        anónima debidamente inscrita al Folio No. _________________, de la
        Sección de Mercantil del Registro Público, del Registro Público de
        Panamá, debidamente facultado para este acto, quien en adelante se
        denominará{" "}
        <Bold>
          <Bold>EL ASOCIADO</Bold>,
        </Bold>{" "}
        y cuando actúe conjuntamente con
        <Bold>FËANOR </Bold>se denominarán <Bold>LAS PARTES</Bold>, han decidido
        celebrar el presente Anexo No. 1 al Contrato de Asociación de acuerdo
        con las siguientes cláusulas:
      </Paragraph>
      <Center>I. ANTECEDENTES</Center>
      <Paragraph>
        PRIMERA (CONTRATO DE ASOCIACIÓN): Declaran <Bold>LAS PARTES</Bold> que
        celebraron contrato de Asociación, en adelante EL CONTRATO, el
        _______________________.
      </Paragraph>
      <Paragraph>
        SEGUNDA (<Bold>EL NEGOCIO</Bold>): Declaran <Bold>LAS PARTES</Bold> que
        conjuntamente han logrado la contratación de un servicio para los
        referidos de{" "}
        <Bold>
          <Bold>EL ASOCIADO</Bold>,
        </Bold>{" "}
        en adelante, <Bold>LOS REFERIDOS.</Bold>
      </Paragraph>
      <Paragraph>
        El servicio que proveerán a los referidos de <Bold>EL ASOCIADO</Bold> es
        el cambio de criptomonedas en dólares de los Estados Unidos de América,
        que serán depositados en las tarjetas de crédito prepago (en adelante{" "}
        <Bold>LA TARJETA</Bold>) que emita <Bold>FËANOR </Bold>a favor de los
        referidos de{" "}
        <Bold>
          <Bold>EL ASOCIADO</Bold>,
        </Bold>{" "}
        en adelante EL NEGOCIO, previo cumplimiento de los requerimientos de
        VISA-MC y de FËANOR.
      </Paragraph>
      <Paragraph>
        TERCERA (REFERIDOS): Declaran <Bold>LAS PARTES</Bold> que para efecto de
        este contrato se entiende como REFERIDOS toda persona natural o jurídica
        que <Bold>EL ASOCIADO</Bold> vincule a la FËANOR.
      </Paragraph>
      <Center>
        II. D<Bold>EL NEGOCIO</Bold>
      </Center>
      <Paragraph>
        PRIMERA (NEGOCIO): Declaran <Bold>LAS PARTES</Bold> que{" "}
        <Bold>FËANOR </Bold>cobrará a los REFERIDOS una comisión de{" "}
        <Bold>CINCO POR CIENTO (5%)</Bold> cada vez que cambien criptomonedas en
        dólares de los Estados Unidos de América, los cuales serán depositados
        en <Bold>LA TARJETA</Bold>.
      </Paragraph>
      <Paragraph>
        SEGUNDA (COMISIÓN DE PAGO): Declaran <Bold>LAS PARTES</Bold> que FËANOR
        pagará a <Bold>EL ASOCIADO</Bold> una comisión por referenciación de UNO
        POR CIENTO (1%) del
        <Bold>CINCO POR CIENTO (5%)</Bold> que cobra a los REFERIDOS por el
        NEGOCIO.
      </Paragraph>
      <Paragraph>
        <Bold>FËANOR </Bold>realizará el pago los días treinta (30) de cada mes
        o el día hábil siguiente, a una wallet de criptomoneda que indicará
        mediante correo electrónico <Bold>EL ASOCIADO</Bold>.
      </Paragraph>
      <Paragraph>
        TERCERA (DE OTRAS COMISIONES): Declaran <Bold>LAS PARTES</Bold> que
        <Bold>FËANOR </Bold>cobrará directamente a los REFERIDOS por el uso de{" "}
        <Bold>LA TARJETA</Bold>, otras comisiones por razón de{" "}
        <Bold>EL NEGOCIO</Bold>. Estas comisiones no forman parte de la comisión
        que <Bold>FËANOR </Bold>paga a <Bold>EL ASOCIADO</Bold> y son propias
        del servicio de <Bold>LA TARJETA</Bold>.
      </Paragraph>
      <Paragraph>Estas comisiones son las siguientes:</Paragraph>
      <Paragraph>
        1){" "}
        <Bold>
          Cargo mensual por seguro contra fraude de tarjeta de crédito: EL
          REFERIDO
        </Bold>{" "}
        pagará un cargo de:
      </Paragraph>
      <Paragraph>
        <table border={"collapse"}>
          <thead>
            <tr>
              <th style={{ background: "yellow" }}>PRODUCTO</th>
              <th style={{ background: "yellow" }}>
                <Bold>FËANOR </Bold>Card
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>CARGO</td>
              <td>USD 5.25</td>
            </tr>
            <tr>
              <td>Cobertura hasta</td>
              <td>USD 15,000.00</td>
            </tr>
          </tbody>
        </table>
      </Paragraph>
      <Paragraph>
        2){" "}
        <Bold>
          Cargo por reposición de tarjeta deteriorada, perdida, robada o hurtada
        </Bold>
        : EL REFERIDO pagará un cargo de VEINTE DÓLARES CON 00/100 CENTAVOS DE
        DÓLAR (USD 20.00), moneda de curso legal de los Estados Unidos de
        América, por reposición de cualquier tarjeta deteriorada, perdida,
        robada o hurtada.
      </Paragraph>
      <Paragraph>
        3) <Bold>Cargo por reimpresión de PIN</Bold>: EL REFERIDO pagará un
        cargo de QUINCE DÓLARES CON 00/100 CENTAVOS DE DÓLAR (USD 15.00) moneda
        de curso legal de los Estados Unidos de América, al solicitar la
        reimpresión de su PIN (Número secreto) necesario para realizar retiros
        de efectivo en cajeros automáticos.
      </Paragraph>
      <Paragraph>
        4) <Bold>Cargo por adelanto en efectivo</Bold>: EL REFERIDO pagará un
        cargo de TRES DÓLARES CON 50/100 CENTAVOS DE DÓLARES (USD 3.50) fijos
        sobre montos menores o iguales a CIEN DÓLARES CON 00/100 CENTAVOS DE
        DÓLAR (USD 100.00) o el TRES PUNTO CINCO por ciento (3.5%) sobre el
        monto del retiro mayor a CIEN DÓLARES CON 00/100 CENTAVOS DE DÓLAR (USD
        100.00), moneda de curso legal de los Estados Unidos de América, por los
        adelantos en efectivo.
      </Paragraph>
      <Paragraph>
        5) <Bold>Cargos por compras realizadas en moneda extranjera</Bold>: Se
        incluirá un cargo del UNO por ciento (1%) de la cantidad de la compra en
        moneda extranjera después de la conversión a dólares de Estados Unidos
        de América.
      </Paragraph>
      <Paragraph>
        6) <Bold>Contra cargos</Bold>: EL REFERIDO pagará un cargo de
        VEINTICINCO DÓLARES CON 00/100 CENTAVOS DE DÓLAR (USD 25.00) moneda de
        curso legal de los Estados Unidos de América, por Contracargo gestionado
        por <Bold>LA TARJETA</Bold>.
      </Paragraph>
      <Paragraph>
        7){" "}
        <Bold>Cargo por copias de voucher al cliente por el contra cargo</Bold>:
        EL REFERIDO pagará un cargo de QUINCE DÓLARES CON 00/100 CENTAVOS DE
        DÓLAR (USD 15.00), moneda de curso legal de los Estados Unidos de
        América, por voucher suplido. De acuerdo con el mercado este costo se
        les traslada a EL REFERIDO.
      </Paragraph>
      <Paragraph>
        8){" "}
        <Bold>
          Membresía anual por acceso a la PLATAFORMA DE <Bold>FËANOR </Bold>y
          servicio de TARJETA
        </Bold>
        : EL REFERIDO pagará una membresía anual de DOSCIENTOS VEINTICINCO
        DÓLARES (USD 225.00) por la afiliación a PLATAFORMA DE FËANOR.
      </Paragraph>
      <Paragraph>
        9) Cualquier otro cargo que <Bold>FËANOR </Bold>cobre a EL REFERIDO.
      </Paragraph>
      <Paragraph>
        <Bold>CUARTA(DOCUMENTOS ADICIONALES):</Bold> Declaran{" "}
        <Bold>LAS PARTES</Bold> que el cumplimiento de <Bold>EL NEGOCIO</Bold>{" "}
        está condicionado a que EL REFERIDO cumplan con lo siguiente:
        <Paragraph>1) Debida Diligencia de FËANOR.</Paragraph>
        <Paragraph>
          2) Firma del Contrato de Compraventa de Activos Digitales.
        </Paragraph>
        <Paragraph>
          3) Firma del Contrato de Tarjeta de Crédito Prepago.
        </Paragraph>
        <Paragraph>4) Firma del Contrato de Plataforma FËANOR.</Paragraph>
      </Paragraph>
      <Paragraph>
        <Bold>QUINTA (DECLARACIÓN):</Bold> Declaran <Bold>LAS PARTES</Bold> que
        se mantiene los demás términos de EL CONTRATO.
      </Paragraph>
      <Paragraph>
        <Bold>EN FE DE LO CUAL,</Bold> firmamos el presente Contrato en la
        República de Panamá, a los ____ (__) días del mes __________ de dos mil
        veintitres (2023).
      </Paragraph>
      <br />
      <br />
      <br />
      <br />
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>___________________________</div>
          <div>
            <Bold>FEÄNOR,</Bold>
          </div>
          <div>BELISARIO CASTILLO SÁENZ</div>
          <div>Cédula No. 2-700-1677</div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>___________________________</div>
          <div>ASOCIADO</div>
          <div>Cédula No. ______________</div>
        </div>
      </div>
    </div>
  );
};
export default AsociationContract;
