import React from "react";
import { Paragraph, Center, Subtitle, Bold } from "./styles";
const PlatformContract = () => {
  return (
    <div style={{ color: "black" }} id="plataforma">
      <Center>CONTRATO DE PLATAFORMA FËANOR</Center>
      <br />
      <Paragraph>
        <Bold>FËANOR</Bold> es una empresa que provee una plataforma de
        servicios para facilitar transacciones de activos digitales a nivel
        global, por una parte y, por la otra,
      </Paragraph>
      <Paragraph>
        <Bold>EL CLIENTE</Bold>, quien ha suscrito con <Bold>FËANOR</Bold>,
        contrato de compraventa de activos digitales, con respecto a los cuales
        está interesado en recibir el servicio de plataforma de activos
        digitales vía Internet ofrecido por
        <Bold>FËANOR</Bold>, sujeto a los siguientes
      </Paragraph>
      <Center>TÉRMINOS Y CONDICIONES DEL SERVICIO:</Center>
      <Subtitle>1. OBJETO</Subtitle>
      <Paragraph>
        El presente contrato regula el acceso electrónico de{" "}
        <Bold>EL CLIENTE</Bold> a los servicios que ofrece <Bold>FËANOR</Bold>,
        a través de la sección transaccional de la página publicadas por{" "}
        <Bold>FËANOR</Bold> para dichos efectos en el World Wide Web (www), que
        opera bajo el siguiente dominio: www.feanor.io.
      </Paragraph>
      <Subtitle>2. DEFINICIONES</Subtitle>
      <Paragraph>
        Los siguientes términos tendrán los significados que se detallan a
        continuación:
      </Paragraph>
      <Paragraph>
        a) “Código de Acceso” es la identificación de <Bold>EL CLIENTE</Bold> o
        de las Personas Autorizadas dentro del Servicio.
      </Paragraph>
      <Paragraph>
        b) “Contraseña” es el distintivo alfanumérico confidencial utilizado por
        <Bold>EL CLIENTE</Bold> o por las Personas Autorizadas para acceder el
        Sistema.
      </Paragraph>
      <Paragraph>
        c) "Persona Autorizada" se refiere a las personas que{" "}
        <Bold>EL CLIENTE</Bold>
        autorice a tener acceso a las cuentas a través del Servicio, mediante la
        debida notificación en los formularios que <Bold>FËANOR</Bold>{" "}
        proporcione para este propósito, y el cual se adjuntará al presente
        contrato, formando parte de este.
      </Paragraph>
      <Paragraph>
        d) “Instrucciones” se refiere a cualquier instrucción, dato u otra
        comunicación en relación con las Cuentas y/o a los Servicios,
        transmitida a <Bold>FËANOR</Bold> a través del Sistema, por{" "}
        <Bold>EL CLIENTE</Bold> o una Persona Autorizada. Toda Instrucción
        deberá ser emitida en base a las disposiciones del presente documento, y
        en la forma que de tiempo en tiempo acuerden las partes.
      </Paragraph>
      <Paragraph>
        e) “<Bold>LA PLATAFORMA</Bold>” es el servicio de consulta e
        instrucciones por medio de una computadora a través de Internet o
        cualquier medio de transaccionalidad electrónico que consiste en que{" "}
        <Bold>EL CLIENTE</Bold> logra tener acceso a una computadora o servidor
        de <Bold>FËANOR</Bold> a través de una conexión a la página de Internet
        o “website” de <Bold>FËANOR</Bold>, según las disposiciones establecidas
        en este contrato.
      </Paragraph>
      <Subtitle>3. DESCRIPCIÓN DEL SERVICIO</Subtitle>
      <Paragraph>
        <Bold>FËANOR</Bold> tiene en funcionamiento el sistema de plataforma de
        compraventa de activos digitales, (en adelante<Bold>EL SERVICIO</Bold>)
        que es un servicio de consulta e instrucciones mediante el cual{" "}
        <Bold>EL CLIENTE</Bold> logra tener acceso a una computadora o servidor
        de <Bold>FËANOR</Bold> por intermedio de una conexión vía Internet, se
        le permite a <Bold>EL CLIENTE</Bold>, el acceso directo al servidor de{" "}
        <Bold>FËANOR</Bold>, que identifica y valida el PIN o clave de usuario
        de <Bold>EL CLIENTE</Bold> mediante perfiles de autorización definidos,
        la cual luego de haberlo identificado electrónicamente permitirá a{" "}
        <Bold>EL CLIENTE</Bold> o las personas autorizadas a realizar todo tipo
        de transacciones de compraventa de activos digitales disponibles a
        través de<Bold>EL SERVICIO</Bold>.
      </Paragraph>
      <Paragraph>
        <Bold>EL CLIENTE</Bold> pagará una comisión anual de US$225.00 por la
        afiliación y uso de <Bold>LA PLATAFORMA</Bold> de <Bold>FËANOR</Bold>.
      </Paragraph>
      <Subtitle>4. DERECHOS DE USO DEL SISTEMA</Subtitle>
      <Paragraph>
        a) Derecho de Uso y Requisitos del Sistema: <Bold>FËANOR</Bold>{" "}
        garantiza a <Bold>EL CLIENTE</Bold> un derecho no exclusivo de uso del
        Sistema y de los Servicios de acuerdo con los términos de este Contrato.
        Para hacer uso del Sistema, <Bold>EL CLIENTE</Bold> deberá incluir sus
        cuentas activas y deberá tener al menos una Cuenta activa en todo
        momento con <Bold>FËANOR</Bold>, así como un equipo informático
        (hardware, software y servicio de Internet). Serán por cuenta de{" "}
        <Bold>EL CLIENTE</Bold> los gastos relacionados con la adquisición e
        instalación del equipo necesario y de los servicios de acceso a Internet
        necesarios para poder hacer uso de los Servicios descritos en el
        presente Contrato.
      </Paragraph>
      <Paragraph>
        <Bold>EL CLIENTE</Bold> podrá hacer uso de los Servicios de la
        Plataforma, única y exclusivamente con respecto a las Cuentas indicadas
        por él. <Bold>EL CLIENTE</Bold>
        podrá adicionar o excluir cuentas en el Sistema a su discreción
      </Paragraph>
      <Paragraph>
        b) Uso no autorizado del Sistema: <Bold>EL CLIENTE</Bold> garantiza que
        ni él ni sus empleados, agentes y/u otras terceras personas utilizarán
        el Sistema, los Servicios o cualquier componente de estos, para fines
        distintos a los autorizados en el presente Contrato.
      </Paragraph>
      <Paragraph>
        c) Prohibición de sublicencia o distribución: <Bold>EL CLIENTE</Bold>{" "}
        utilizará el Sistema y los Servicios sólo para el ejercicio de sus
        propias actividades y no sublicenciará, distribuirá u ofrecerá el
        Sistema, los Servicios o datos y/o la información comunicada a través
        del Sistema a ninguna persona excepto las Personas Autorizadas.
      </Paragraph>
      <Subtitle>5. CÓDIGO DE ACCESO</Subtitle>
      <Paragraph>
        a) Código de Acceso y Contraseña: El uso de <Bold>LA PLATAFORMA</Bold>,
        por parte de
        <Bold>EL CLIENTE</Bold>, requerirá del uso combinado de un número de
        identificación del usuario (User ID) y un número de clave de
        identificación personal (Password), otorgados a <Bold>EL CLIENTE</Bold>{" "}
        por <Bold>FËANOR</Bold>. Queda expresamente convenido que será
        responsabilidad exclusiva de <Bold>EL CLIENTE</Bold>, el buen uso y
        conservación del User ID y el Password, especialmente la
        confidencialidad de estos, para evitar usos no autorizados. Será de
        exclusiva responsabilidad de <Bold>EL CLIENTE</Bold> el compartir con
        otra u otras personas su User ID y su Password, para el acceso, de tales
        personas, a los servicios objeto del presente contrato. No obstante,{" "}
        <Bold>EL CLIENTE</Bold>
        podrá autorizar a terceros el uso de <Bold>LA PLATAFORMA</Bold> para el
        manejo de su(s) cuenta(s). En estos casos <Bold>EL CLIENTE</Bold> deberá
        reportar a <Bold>FËANOR</Bold> el nombre, apellido y demás datos de
        identificación personal a efectos de que <Bold>FËANOR</Bold> le
        suministre un número diferente de User ID y de Password, de manera tal
        que se le pueda identificar individualmente en el uso de los servicios
        ofrecidos en este contrato. Queda expresamente convenido que en estos
        casos en que EL CLIENTE autorice a terceros el uso de los servicios
        objeto del presente contrato, serán de su exclusiva responsabilidad y
        del tercero autorizado, los daños y/o perjuicios que el mal uso del
        sistema pueda ocasionarle a <Bold>EL CLIENTE</Bold> o a terceros.
      </Paragraph>
      <Paragraph>
        Toda comunicación electrónica que cumpla con los requisitos de
        identificación antes indicados será considerada válida y auténtica, por
        lo que estas comunicaciones electrónicas, entre las partes contratantes,
        tendrán la misma fuerza legal que si hubiesen sido escritas y firmadas
        en soportes materiales.
      </Paragraph>
      <Paragraph>
        <Bold>EL CLIENTE</Bold> autoriza a <Bold>FËANOR</Bold> a ejecutar las
        Instrucciones recibidas a través de <Bold>LA PLATAFORMA</Bold>, con el
        uso del User ID y del Password. Si <Bold>EL CLIENTE</Bold> olvidará su
        USER ID y/o Password, deberá contactar a <Bold>FËANOR</Bold>
        directamente para obtener una nueva contraseña temporal. Sin perjuicio
        de lo anterior, <Bold>FËANOR</Bold> podrá, de tiempo en tiempo, asignar
        nuevas contraseñas de acceso a <Bold>EL CLIENTE</Bold>, lo cual será
        comunicado por <Bold>FËANOR</Bold> a <Bold>EL CLIENTE</Bold>{" "}
        oportunamente.
      </Paragraph>
      <Paragraph>
        b) Limitación de Responsabilidad: <Bold>EL CLIENTE</Bold> libera de toda
        responsabilidad a <Bold>FËANOR</Bold> derivados de los daños, pérdidas y
        perjuicios que pudiera producir el uso no autorizado del Código de
        Acceso o de la Contraseña de <Bold>EL CLIENTE</Bold> o de las Personas
        Autorizadas, igualmente, por el hecho de no revisar diariamente el
        correo electrónico que ha proporcionado para mantener comunicación con{" "}
        <Bold>FËANOR</Bold>.
      </Paragraph>
      <Paragraph>
        <Bold>EL CLIENTE</Bold> acepta que la totalidad de las claves que se
        generen para la utilización del servicio ofrecido en virtud de la
        celebración de este contrato son secretas, personales e intransferibles
        y por lo tanto se obliga a no divulgarlas. <Bold>EL CLIENTE</Bold>{" "}
        declara aceptar que <Bold>LA PLATAFORMA</Bold>, únicamente se puede
        acceder mediante la verificación del User ID y del Password, por lo
        tanto, al entregar <Bold>FËANOR</Bold>, el User ID y el Password,
        únicamente al <Bold>EL CLIENTE</Bold>, éste es el único responsable por
        todas las transacciones que se realicen a través de{" "}
        <Bold>LA PLATAFORMA</Bold> y donde se verifique el sistema dual de
        identificación, las partes entienden que fue <Bold>EL CLIENTE</Bold> que
        la realizó y en consecuencia <Bold>EL CLIENTE</Bold>
        es quien asume directamente la responsabilidad por las transacciones
        realizadas.
      </Paragraph>
      <Paragraph>
        De esta forma <Bold>EL CLIENTE</Bold>, exonera de toda responsabilidad a{" "}
        <Bold>FËANOR</Bold>, sus directores accionistas, trabajadores por las
        operaciones o transacciones que realice en <Bold>LA PLATAFORMA</Bold>,
        siempre que en la autorización de la transacción se hubiera suministrado
        por el correspondiente User ID y el Password. Toda transacción u
        operación en donde se hubiere verificado el User ID y el Password,
        impedirá su reversión o reclamo a <Bold>FËANOR</Bold>. Adicionalmente,{" "}
        <Bold>FËANOR</Bold> no será responsable por los daños y perjuicios que
        pudieran causar a <Bold>EL CLIENTE</Bold> o a las Personas Autorizadas
        la imposibilidad de acceder a <Bold>LA PLATAFORMA</Bold>, debido al
        olvido del Código de Acceso, de la Contraseña, o por interrupciones del
        Sistema, independientemente de la causa de estas interrupciones.
      </Paragraph>
      <Subtitle>6. SERVICIOS</Subtitle>
      <Paragraph>
        a) Modificación de los Servicios: <Bold>FËANOR</Bold> se reserva el
        derecho de ampliar, limitar, adicionar, eliminar o en cualquier otra
        forma modificar los Servicios prestados a través de{" "}
        <Bold>LA PLATAFORMA</Bold> en cualquier momento, sin que para ello sea
        necesario solicitar la autorización o notificar previamente a El
        CLIENTE.
      </Paragraph>
      <Paragraph>
        b) Insuficiencia de Fondos: La prestación por parte de{" "}
        <Bold>FËANOR</Bold> de los Servicios solicitados por{" "}
        <Bold>EL CLIENTE</Bold> o por las Personas Autorizadas a través del
        Sistema estará sujeta, entre otras cosas, a la existencia de fondos
        suficientes en la Cuenta respectiva para ejecutar la transacción
        solicitada en su totalidad. <Bold>FËANOR</Bold> no estará obligado a
        cumplir parcialmente una orden dada por <Bold>EL CLIENTE</Bold> o por
        las Personas Autorizadas a través de <Bold>LA PLATAFORMA</Bold>.
      </Paragraph>
      <Paragraph>
        c) Servicios brindados por <Bold>FËANOR</Bold> a través de LA
        PLATAFORMA:
        <Paragraph>
          i) Consulta: <Bold>EL CLIENTE</Bold> podrá revisar, los movimientos,
          saldos de las Cuentas y los créditos otorgados por <Bold>FËANOR</Bold>{" "}
          a favor de EL CLIENTE. La información sobre cada Cuenta es actualizada
          por <Bold>FËANOR</Bold> en tiempo real, con excepción de aquellas
          transacciones para las cuales <Bold>FËANOR</Bold>
          ha establecido un plazo determinado para su ejecución.{" "}
          <Bold>FËANOR</Bold> no es responsable por las consecuencias derivadas
          de las decisiones que realice <Bold>EL CLIENTE</Bold> en base a la
          información de las Cuentas disponible a través de{" "}
          <Bold>LA PLATAFORMA</Bold>.
        </Paragraph>
        <Paragraph>
          ii) Compraventa de Activos Digitales: <Bold>EL CLIENTE</Bold> podrá
          comprar y vender activos digitales que ofrece <Bold>FËANOR</Bold>.
        </Paragraph>
        <Paragraph>
          iii) Otros Servicios: <Bold>FËANOR</Bold> brindará los siguientes
          Servicios adicionales, sujetos a la disponibilidad de fondos en las
          Cuentas de <Bold>EL CLIENTE</Bold> y a los reglamentos internos
          aprobados por <Bold>FËANOR</Bold> para la ejecución de dichos
          Servicios.
        </Paragraph>
        <Paragraph>
          Las compras que se realicen a través de <Bold>LA PLATAFORMA</Bold> se
          harán mediante un débito a la cuenta de <Bold>EL CLIENTE</Bold>, por
          la cantidad indicada por éste. <Bold>FËANOR</Bold> comprará los
          activos digitales y pondrá en la cuenta de <Bold>EL CLIENTE</Bold> en
          LA PLATAFORMA.
        </Paragraph>
      </Paragraph>
      <Paragraph>
        d) Ejecución de los Servicios solicitados por <Bold>EL CLIENTE</Bold>:
        Con excepción de las Consultas y de aquellos Servicios que según los
        reglamentos internos de <Bold>FËANOR</Bold> tengan un plazo de ejecución
        mayor, los Servicios que solicite <Bold>EL CLIENTE</Bold> a través de LA
        PLATAFORMA serán ejecutados por
        <Bold>FËANOR</Bold> a más tardar el Día Hábil siguiente, cuando{" "}
        <Bold>FËANOR</Bold> reciba las Instrucciones de <Bold>EL CLIENTE</Bold>{" "}
        antes de la 10:00 a.m., hora de la República de Panamá (GMT – 5 hrs).
      </Paragraph>
      <Paragraph>
        Las Instrucciones que <Bold>FËANOR</Bold> reciba de{" "}
        <Bold>EL CLIENTE</Bold> con posterioridad a dicha hora o en días que no
        sean Días Hábiles, serán ejecutadas a más tardar el segundo Día Hábil
        siguiente.
      </Paragraph>
      <Paragraph>
        e) Cancelación de Instrucciones emitidas por <Bold>EL CLIENTE</Bold>:
        Las Instrucciones emitidas por <Bold>EL CLIENTE</Bold> a través de LA
        PLATAFORMA no podrán ser canceladas una vez la misma haya sido ejecutada
        por <Bold>FËANOR</Bold>.
      </Paragraph>
      <Subtitle>7. MEDIDAS DE SEGURIDAD</Subtitle>
      <Paragraph>
        a) Obligaciones de Seguridad de <Bold>EL CLIENTE</Bold>:
        <Paragraph>
          i) <Bold>EL CLIENTE</Bold> tomará las medidas de seguridad adecuadas
          para salvaguardar el Sistema de robo o acceso por parte de otras
          personas distintas a <Bold>EL CLIENTE</Bold> y las Personas
          Autorizadas, y además cumplirá con las disposiciones de seguridad de
          este contrato y las notificadas periódicamente por <Bold>FËANOR</Bold>{" "}
          a <Bold>EL CLIENTE</Bold> por escrito. EL CLIENTE es responsable del
          cumplimiento de las medidas de seguridad por parte de las Personas
          Autorizadas.
        </Paragraph>
      </Paragraph>
      <Paragraph>
        b) Violación a la Seguridad: <Bold>EL CLIENTE</Bold> notificará
        inmediatamente a<Bold>FËANOR</Bold> por teléfono o{" "}
        <Bold>LA PLATAFORMA</Bold> en caso de tener razones para creer que:
        <Paragraph>
          i) El User ID y el Password u otro procedimiento de autenticación
          puede haber sido puesto en peligro, manipulado, robado, colocado en un
          lugar inadecuado, perdido o utilizado incorrectamente, o alguno de
          dichos acontecimientos fue o pudo haber sido de conocimiento de alguna
          persona diferente de <Bold>EL CLIENTE</Bold> o las Personas
          Autorizadas.
        </Paragraph>
        <Paragraph>
          ii) Ha habido una falla o demora en la recepción de cualquier; o
        </Paragraph>
        <Paragraph>
          iii) Existe o se sospecha la existencia de: (a) algún error o fraude
          en la Cuenta; (b) cualquier error de programación, defecto o
          corrupción de alguna Instrucción; (c) cualquier incumplimiento real o
          potencial de cualquier disposición de seguridad de este contrato.
        </Paragraph>
        <Paragraph>
          iv) Ante la ocurrencia de cualesquiera de los hechos anteriormente
          descritos, <Bold>EL CLIENTE</Bold> se compromete a cumplir con las
          instrucciones de seguridad de <Bold>FËANOR</Bold>. Inmediatamente
          después de advertir una violación a la seguridad que afecte el User ID
          o el Password EL CLIENTE modificará el User ID o el Password y, al
          recibir la debida notificación, <Bold>FËANOR</Bold> llevará a cabo las
          acciones razonables para impedir el acceso al Sistema por medio del
          User ID o Password, <Bold>EL CLIENTE</Bold> será responsable por
          cualquier suma perdida o reclamada que surja de dicha violación a la
          seguridad o con relación a ella.
        </Paragraph>
        <Paragraph>
          v) En el caso de que las instrucciones sean dadas telefónicamente o
          mediante <Bold>LA PLATAFORMA</Bold>, <Bold>FËANOR</Bold> queda
          autorizado para actuar bajo instrucciones que no contengan la firma
          original de EL CLIENTE con el mismo efecto como si las instrucciones
          fueran firmadas por <Bold>EL CLIENTE</Bold>. Sin embargo,{" "}
          <Bold>FËANOR</Bold> puede, a su discreción, requerir la firma original
          de <Bold>EL CLIENTE</Bold> o cualquier otra documentación antes de
          actuar bajo las instrucciones.
        </Paragraph>
        <Paragraph>
          vi) <Bold>EL CLIENTE</Bold> autoriza a <Bold>FËANOR</Bold> a grabar
          cualquier llamada telefónica que afecte sus cuentas o los servicios
          que solicite a <Bold>FËANOR</Bold>, con el propósito de asegurar su
          autenticidad y veracidad, llevar un registro de dichas conversaciones,
          así como de implementar los mecanismos y procedimientos de seguridad y
          confiabilidad que estime pertinentes para la utilización de LA
          PLATAFORMA.
        </Paragraph>
      </Paragraph>
      <Subtitle>8. AUTORIZACIÓN Y EJECUCIÓN DE LAS INSTRUCCIONES</Subtitle>
      <Paragraph>
        a) Instrucciones:
        <Paragraph>
          i) Se entenderá que <Bold>FËANOR</Bold> ha recibido una Instrucción
          sólo cuando la recepción de dicha Instrucción haya sido reconocida por
          <Bold>LA PLATAFORMA</Bold>. Lo anterior es sin perjuicio del derecho
          discrecional de <Bold>FËANOR</Bold> de no ejecutar una Instrucción
          según lo establecido en la sección 8-e (Errores en el cumplimiento de
          las instrucciones /Bloqueo de acceso al Sistema).
        </Paragraph>
        <Paragraph>
          ii) <Bold>EL CLIENTE</Bold> hará todo aquello que <Bold>FËANOR</Bold>{" "}
          requiera y entregará a<Bold>FËANOR</Bold> los documentos y/o
          información que <Bold>FËANOR</Bold> pueda requerirle periódicamente
          para la verificación de la autenticidad y veracidad de las
          Instrucciones.
        </Paragraph>
      </Paragraph>
      <Paragraph>
        b) Confianza de <Bold>FËANOR</Bold> en las Instrucciones: Sin perjuicio
        de lo establecido en la Sección 8, Literal “a” (punto anterior),{" "}
        <Bold>FËANOR</Bold> podrá confiar categóricamente, en la autenticidad y
        debida autorización de cualquier Instrucción u otras comunicaciones que
        lleguen a <Bold>FËANOR</Bold> a través de <Bold>LA PLATAFORMA</Bold>, o
        que sean entregadas por <Bold>EL CLIENTE</Bold> o las Personas
        Autorizadas a <Bold>FËANOR</Bold> por medios electrónicos, en la forma
        especificada en el presente contrato, quedando <Bold>FËANOR</Bold>{" "}
        debidamente autorizado por <Bold>EL CLIENTE</Bold> a ejecutar esas
        Instrucciones y/u otras comunicaciones.
      </Paragraph>
      <Paragraph>
        c) Instrucciones incompletas: <Bold>FËANOR</Bold> podrá ejecutar
        Instrucciones incompletas que, en la opinión razonable de{" "}
        <Bold>FËANOR</Bold>, contienen información suficiente para inferir sus
        propósitos.
      </Paragraph>
      <Paragraph>
        d) Uso de intermediarios, corresponsales y sistemas de comunicaciones:
        <Bold>FËANOR</Bold> podrá utilizar Exchange, intermediarios y otras
        terceras partes, y podrá seleccionar y emplear sistemas de comunicación
        y de transmisión de datos para ejecutar las Instrucciones, en la
        provisión de servicios o en el desempeño de sus obligaciones conforme a
        este contrato y cualesquiera otros acuerdos con <Bold>EL CLIENTE</Bold>.
        Los derechos de <Bold>FËANOR</Bold>
        conforme a esta Cláusula serán en complemento de cualesquiera otros
        derechos que <Bold>FËANOR</Bold> pueda tener conforme a otros contratos,
        y no en contraposición de estos.
      </Paragraph>
      <Paragraph>
        e) Errores en el cumplimiento de las Instrucciones/Bloqueo de acceso al
        Sistema: En aquellas circunstancias en que <Bold>FËANOR</Bold>, actuando
        razonablemente, lo considere necesario o recomendable, podrá:
        <Paragraph>
          i) Optar por no ejecutar cualquiera o todas las Instrucciones; y/o
        </Paragraph>
        <Paragraph>
          ii) Impedir el acceso de <Bold>EL CLIENTE</Bold> o a las Personas
          Autorizadas a <Bold>LA PLATAFORMA</Bold>. En estos casos{" "}
          <Bold>FËANOR</Bold> notificará, dentro de un término prudencial, a{" "}
          <Bold>EL CLIENTE</Bold> por vía electrónica u otro medio legal
          existente entre <Bold>FËANOR</Bold> y <Bold>EL CLIENTE</Bold>{" "}
          explicando el hecho y los motivos que llevaron a <Bold>FËANOR</Bold> a
          tomar dicha decisión.
        </Paragraph>
      </Paragraph>
      <Subtitle>9. CUENTAS</Subtitle>
      <Paragraph>
        <Bold>EL CLIENTE</Bold> reconoce que cualquier Información Financiera
        y/o de cualquier otro tipo que reciba a través del Sistema de la base de
        datos de <Bold>FËANOR</Bold> es para el único fin de ejecutar las
        Instrucciones. <Bold>EL CLIENTE</Bold> no adquirirá derechos de ningún
        tipo o clase sobre dicha información ni tendrá derecho a usar ni copiar
        dicha información para ningún fin.
      </Paragraph>
      <Subtitle>10. RESPONSABILIDAD</Subtitle>
      <Paragraph>
        <Bold>FËANOR</Bold> no será responsable por cualquier pérdida derivada
        de este contrato o con respecto a <Bold>LA PLATAFORMA</Bold>{" "}
        causado-directa o indirectamente por:
      </Paragraph>
      <Paragraph>
        a) Cualquier tiempo de inactividad o falta de disponibilidad, falla,
        error de funcionamiento, distorsión o interrupción de cualquier hardware
        de computadoras, equipo, software, aplicación o de cualquier línea
        telefónica u otro Sistema de comunicación o datos, nexo de servicio,
        proveedor de servicio o equipo;
      </Paragraph>
      <Paragraph>
        b) Contaminación de virus informáticos o de programas de espionaje, de
        publicidad u otros;
      </Paragraph>
      <Paragraph>
        c) Cualquier error, discrepancia, ambigüedad, encriptación inadecuada o
        incompleta de cualquier Instrucción, información relacionada con las
        Cuentas o la Información Financiera o cualquier demora en la entrega de
        cualquiera de ellas;
      </Paragraph>
      <Paragraph>
        d) Recepción por parte de personas no autorizadas, de cualquier
        información, documentación, Instrucciones, informes o mensajes
        confidenciales u otros, referidos a <Bold>LA PLATAFORMA</Bold>, los
        Servicios o EL CLIENTE, producida por el incumplimiento de las Medidas
        de Seguridad establecidas en la Cláusula "Medidas de Seguridad”;
      </Paragraph>
      <Paragraph>
        e) Cualquier acción llevada a cabo por <Bold>FËANOR</Bold> conforme a la
        Cláusula 8, Literal “e” (Errores en el cumplimiento de las
        instrucciones/bloqueo de acceso al Sistema);
      </Paragraph>
      <Paragraph>
        f) Cualquier uso de <Bold>LA PLATAFORMA</Bold> para fines no autorizados
        o de manera inconsistente o en contravención de cualquiera de las
        Cláusulas de este contrato o que infrinja los derechos de un tercero;
      </Paragraph>
      <Paragraph>
        g) Cualquier hecho, acto, omisión o circunstancia fuera del control
        razonable de <Bold>FËANOR</Bold>, sea que dicha pérdida fuera
        razonablemente previsible para <Bold>FËANOR</Bold> o no.
      </Paragraph>
      <Subtitle>11. CESIÓN</Subtitle>
      <Paragraph>
        <Bold>EL CLIENTE</Bold> no podrá transferir derecho u obligación alguna
        contenida en este contrato sin el consentimiento previo y por escrito de{" "}
        <Bold>FËANOR</Bold>.
      </Paragraph>
      <Subtitle>12. CARGOS</Subtitle>
      <Paragraph>
        <Bold>EL CLIENTE</Bold> acepta pagar a <Bold>FËANOR</Bold> un cargo por
        membrecía anual que será determinado por <Bold>FËANOR</Bold>, en caso de
        que <Bold>FËANOR</Bold> así lo disponga. Además, <Bold>EL CLIENTE</Bold>{" "}
        acepta pagar todas las tarifas y comisiones asociadas con los productos
        y servicios que obtenga de <Bold>FËANOR</Bold>, incluyendo las tarifas y
        comisiones que suministrará <Bold>FËANOR</Bold> a{" "}
        <Bold>EL CLIENTE</Bold>. Queda entendido que
        <Bold>FËANOR</Bold> podrá modificar los cargos de membrecía anual y las
        tarifas y comisiones asociadas con los productos y servicios a que se
        refiere este contrato, lo cual será comunicado en la forma en que se
        establece en la Cláusula 11 de este contrato.
      </Paragraph>
      <Paragraph>
        Queda entendido y convenido que <Bold>EL CLIENTE</Bold> correrá con
        todos los impuestos, tasas, tributos o contribuciones que recaen o
        lleguen a recaer sobre las operaciones que realice a través de LA
        PLATAFORMA.
      </Paragraph>
      <Subtitle>13. DEBIDA DILIGENCIA Y AUTORIZACIÓN DE DATOS</Subtitle>
      <Paragraph>
        <Bold>EL CLIENTE</Bold> por este medio autoriza a <Bold>FËANOR</Bold> a
        realizar debida diligencia que contará como mínimo con la siguiente
        información: (a) Identificación Cliente; (b) Verificación de Identidad,
        (c) Verificación de Antecedentes; (d) Evaluación de Riesgo, (e) Perfil
        transaccional del Cliente, (g) cualquier otra información adicional a
        criterio de <Bold>FËANOR</Bold>.
      </Paragraph>
      <Paragraph>
        <Bold>EL CLIENTE</Bold> por este medio da consentimiento libre y expreso
        a <Bold>FËANOR</Bold>
        para recopilar, tratar, procesar y archivar toda la información
        necesaria, ya sea confidencial y/o sensitiva sobre su persona, o
        producto contratado para que <Bold>FËANOR</Bold> puedan: (i) cumplir con
        la presente relación contractual que aquí se contrata, así como
        cualquier otra que actualmente o en el futuro tenga{" "}
        <Bold>EL CLIENTE</Bold> con <Bold>FËANOR</Bold> y/o subsidiarias o
        empresas relacionadas al grupo; (ii) Realizar procesos de debida
        diligencia y cumplimiento, para así poder cumplir con las regulaciones
        relativas a las políticas de conocer a su cliente actuales y las que en
        el futuro sean promulgadas; (iii) Evaluar otros productos y/o servicios
        de <Bold>FËANOR</Bold> y/o subsidiarias o empresas relacionadas al
        grupo, que crea que le puedan interesar o ser conveniente para a{" "}
        <Bold>EL CLIENTE</Bold>; (iv) Enviar comunicaciones de mercadeo y demás
        información de servicios, productos y promociones de <Bold>FËANOR</Bold>{" "}
        y/o subsidiarias o empresas relacionadas al grupo; (v) Administrar
        promociones, encuestas y grupos de análisis, publicidad basada en los
        intereses de <Bold>EL CLIENTE</Bold>, así como realizar investigaciones
        y análisis de <Bold>FËANOR</Bold> y/o subsidiarias o empresas
        relacionadas al grupo; y (vi) para cualquier otra actividad que{" "}
        <Bold>EL CLIENTE</Bold> haya autorizado previamente a{" "}
        <Bold>FËANOR</Bold> o en el futuro o que la legislación vigente en
        materia de protección de datos privado permita.
      </Paragraph>
      <Paragraph>
        De igual manera <Bold>EL CLIENTE</Bold> autoriza expresamente a{" "}
        <Bold>FËANOR</Bold>, y sin requerir una autorización adicional futura,
        para traspasar y/o divulgar toda la información necesaria, ya sea
        confidencial y/o sensitiva sobre su persona y/o operaciones, a: (i)
        Subsidiarias o empresas relacionadas a <Bold>FËANOR</Bold>; (ii) a las
        autoridades y entes reguladores en la forma prevista por la legislación;
        (iii) a empresas de manejo de listados de historial créditos, agencias
        de Información de datos o sus similares; y (v) empresas asesoras de{" "}
        <Bold>FËANOR</Bold>, o de empresas de su mismo grupo tales como, pero
        sin limitar, sus abogados, contadores, calificadores de riesgo y
        empresas aseguradoras.
      </Paragraph>
      <Paragraph>
        <Bold>EL CLIENTE</Bold> autoriza irrevocablemente a <Bold>FËANOR</Bold>{" "}
        para que solicite y obtenga información y documentos relacionados con{" "}
        <Bold>EL CLIENTE</Bold>y sea de oficinas o funcionarios gubernamentales,
        o personas o empresas privadas, tanto nacionales como extranjeras.{" "}
        <Bold>FËANOR</Bold> y las personas que suministren información quedan
        relevados de toda responsabilidad por tal acción.{" "}
        <Bold>EL CLIENTE</Bold> otorga su consentimiento expreso a{" "}
        <Bold>FËANOR</Bold>, para que con los datos que le haya proporcionado
        y/o autorizados a obtener de terceros, pueda crear perfiles,
        evaluaciones, así como para tomar decisiones automatizadas sobre dichos
        perfiles.
      </Paragraph>
      <Paragraph>
        Durante la vigencia del presente contrato, <Bold>EL CLIENTE</Bold>{" "}
        tendrá siempre el derecho a solicitarle a <Bold>FËANOR</Bold>, el acceso
        a su información, su corrección y eliminación, de la forma y medios
        establecidos en las políticas de privacidad en materia de protección de
        datos.
      </Paragraph>
      <Subtitle>
        14. DECLARACIÓN DE{" "}
        <Bold>
          <Bold>EL CLIENTE</Bold>
        </Bold>
        :
      </Subtitle>
      <Paragraph>
        <Bold>EL CLIENTE</Bold> certifica que todos sus fondos son producto de
        actividades comerciales lícitas. Adicionalmente, declara{" "}
        <Bold>EL CLIENTE</Bold> que conoce y se hace responsable del origen
        legal de los fondos con los cuales realizará transacciones con{" "}
        <Bold>FËANOR</Bold>. Si <Bold>EL CLIENTE</Bold> llegare a ser: (a)
        vinculado, por parte de las autoridades nacionales o internacionales
        competentes, a cualquier tipo de investigación por delitos relacionados
        con drogas, tales como pero sin limitarse a tráfico de drogas,
        narcotráfico, terrorismo, secuestro, lavado de activos, financiación del
        terrorismo y/o administración de recursos relacionados con actividades
        terroristas u otros delitos relacionados con el lavado de activos y
        financiación del terrorismo; (b) incluidos en listas para el control de
        lavado de activos y financiación del terrorismo administradas por
        cualquier autoridad nacional o extranjera, tales como la lista de la
        Oficina de Control de Activos en el Exterior (OFAC) emitida por la
        Oficina del Tesoro de los Estados Unidos de América, la lista de la
        Organización de las Naciones Unidas y otras listas públicas relacionadas
        con el tema del lavado de activos y financiación del terrorismo; o (c)
        condenados por parte de las autoridades nacionales o internacionales
        competentes en cualquier tipo de proceso judicial relacionado con la
        comisión de los anteriores delitos en cualquier jurisdicción, se
        entenderá que <Bold>EL CLIENTE</Bold> ha incumplido el presente
        Contrato.
      </Paragraph>
      <Paragraph>
        También se entenderá que <Bold>EL CLIENTE</Bold> ha incumplido este
        Contrato si cualquiera de sus representantes legales se vincula a
        cualquier investigación por parte de las autoridades nacionales o
        internacionales competentes, o se comprueba mediante fallo judicial que
        han incurrido en algunas de las conductas tipificadas como delitos de
        Blanqueo de Capitales en el Código Penal.
      </Paragraph>
      <Paragraph>
        Igualmente, <Bold>EL CLIENTE</Bold> declara que conoce y está en
        cumplimiento con, no ha previamente infringido, y no ha sido acusado de,
        ni condenado por, haber violado la Ley 23 de 27 de abril de 2015, que
        adopta medidas para prevenir el blanqueo de capitales, el financiamiento
        del terrorismo y el financiamiento de la proliferación de armas de
        destrucción masiva y dicta otras disposiciones, al igual que el Decreto
        Ejecutivo 35 de 8 de septiembre de 2022, por medio del cual se
        reglamenta la Ley 23 de 27 de abril de 2015 y cualquier modificación
        futura.
      </Paragraph>
      <Paragraph>
        El incumplimiento por parte de <Bold>EL CLIENTE</Bold>, de acuerdo con
        lo estipulado en esta cláusula, dará lugar a que <Bold>FËANOR</Bold>{" "}
        declare este Contrato resuelto de pleno derecho sin necesidad de previa
        declaración judicial de autoridad competente.
      </Paragraph>
      <Subtitle>15. JURISDICCIÓN Y COMPETENCIA: </Subtitle>
      <Paragraph>
        Este contrato se regirá por sus propios términos y condiciones, los
        cuales <Bold>EL CLIENTE</Bold> declara conocer y acepta, por las
        regulaciones de
        <Bold>FËANOR</Bold> que se encuentren vigentes a la firma del presente
        contrato, por los contratos aplicables que <Bold>FËANOR</Bold>{" "}
        establezca en el futuro y por las Leyes de la República de Panamá.
      </Paragraph>
      <Paragraph>
        Acuerdan <Bold>LAS PARTES</Bold> que cualquier controversia,
        discrepancia, litigio, disputa, reclamo o diferencia originada en
        relación con la ejecución, validez existencia, aplicabilidad, nulidad,
        resolución, terminación o interpretación de este acuerdo o de cualquier
        otra materia vinculada o contenida en él, será resuelta definitivamente
        mediante un Arbitraje de Derecho de conformidad con el reglamento de{" "}
        <Bold>
          CONCILIACIÓN Y ARBITRAJE DEL CENTRO DE ARBITRAJE DE LA CÁMARA DE
          COMERCIO Y AGRICULTURA DE PANAMÁ (CECAP).
        </Bold>
      </Paragraph>
      <Paragraph>
        <Bold>LAS PARTES</Bold> contratantes aceptan de manera expresa que el
        laudo arbitral será definitivo y obligatorio, renunciando a interponer
        cualquier medio de impugnación contra el laudo arbitral. El Laudo
        Arbitral determinará la forma en que deben satisfacerse los gastos
        relacionados con el arbitraje.
      </Paragraph>
      <Paragraph>
        La Ley Aplicable a la validez, efectos, así como cualquier otro asunto
        vinculado al arbitraje y a la materia del mismo será la Ley Panameña.
        <Bold>LAS PARTES</Bold> en general, harán todos sus esfuerzos para que
        mantengan vigente el presente compromiso arbitral, respondiendo por los
        perjuicios de su incumplimiento. Asimismo, renuncian a cualquier acción
        o excepción destinada a limitarlo o anularlo.
      </Paragraph>
      {/* <br />
      <br />
      De acuerdo con todo lo previamente pactado, <Bold> <Bold><Bold>EL CLIENTE</Bold></Bold> </Bold> */}
    </div>
  );
};

export default PlatformContract;
