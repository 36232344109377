import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Access history through withRouter
import { withRouter } from "react-router";
// Spinner Loader
// Styled components
import {
  Section,
  BTCPartnersBox,
  BTCPartners,
  BTCText,
  LogoPartner,
  Wrapper,
  Space,
  Heading2,
  BoxBitcoin,
  Card,
  CardHeading,
  Advantages,
  LoaderWrapper,
} from "styles/Styled";

import { PrimaryText, SecondaryText } from "styles/colorized";

import Styled from "./styles";
import {
  Header1,
  Header3,
  Paragraph,
  ParagraphSubtitle,
  TitleSection,
  Header1White,
  ParagraphSubtitleWhite,
} from "styles/common/index.js";
import CurrencyPrice from "components/CurrencyPrice";

import { Helmet } from "react-helmet";

import feanorapi from "feanorapi.js";
import ReadyForTheFuture from "ReadyForTheFuture";

const KYC = ({ history }) => {
  // Scroll to top on all pages
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollTop();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Styled.AboutContainer>
        <Styled.ContainerSectionHeader>
          <Styled.TaglineAbout>
            <PrimaryText>Prácticas y Estándares de AML / KYC</PrimaryText>
          </Styled.TaglineAbout>
          <Styled.SubtaglineHome>
            <PrimaryText>
              En feanor.io queremos ir a la vanguardia a nivel mundial en
              Prevención del riesgo de Lavado de Activos y Financiamiento del
              Terrorismo, es por esto que contamos con rigurosas políticas,
              procedimientos y herramientas tecnológicas que nos permiten operar
              con los más altos estándares internacionales en nuestra industria.
            </PrimaryText>
          </Styled.SubtaglineHome>
        </Styled.ContainerSectionHeader>
      </Styled.AboutContainer>

      <Styled.ContentBoxedContainer>
        <Header3>Definiciones</Header3>

        <Paragraph>
          AML/CFT es el acrónimo del concepto inglés Anti-Money Laudering and
          Combating the Financing of Terrorism, lo que podríamos traducir como
          Prevención del Lavado de Activos y Financiamiento del Terrorismo. En
          palabras simples, cuando alguna persona obtiene dineros o recursos
          provenientes de ciertos delitos, trata de “lavarlos”, para sacarle la
          “suciedad” del delito y poder utilizarlos en el día a día, intentando
          hacerlos pasar como dinero proveniente de una actividad lícita.
        </Paragraph>

        <Paragraph>
          Para prevenir que nuestra plataforma sea usada en estas actividades,
          contamos con variadas medidas complementarias, cuya aplicación y
          cumplimiento obligatorio son la responsabilidad principal de nuestra
          área de Compliance, involucrando transversalmente a todo el equipo de
          feanor.io, entre las que destacamos:
        </Paragraph>

        <Header3>Manual de Cumplimiento</Header3>

        <Paragraph>
          Todos los procedimientos destinados a la prevención y control de
          riesgo del Lavado de Activos y Financiamiento del Terrorismo están
          contenidos en nuestro completo y actualizado Manual SARLAFT (Sistema
          de Administración del Riesgo de Lavado de Activos y Financiación del
          Terrorismo). En él, se describen nuestras políticas de conocimiento
          del cliente, la asignación de responsabilidades, los controles a los
          Clientes y las medidas a tomar en caso de detectar operaciones
          sospechosas.
        </Paragraph>
        <Paragraph>
          Este Manual es distribuido entre todo el equipo de feanor.io, a
          quienes además se les instruye constantemente en materia de prevención
          de riesgos.
        </Paragraph>

        <Header3>Conocemos a nuestros usuarios</Header3>

        <Paragraph>
          Contamos con softwares de punta como Kibana para perfilar, analizar y
          extraer conclusiones sobre el comportamiento de nuestros usuarios. El
          mismo nos permite organizar, graficar y clasificar las operaciones en
          nuestra plataforma, para determinar patrones de conducta ordinarios y
          extraordinarios. Por otro lado, nos enorgullecemos de ser pioneros
          entre los exchanges de latinoamérica en operar con Elliptic, una
          herramienta que analiza las transacciones de Bitcoin en el blockchain.
          Las características de trazabilidad, inmutabilidad y publicidad de
          cada transacción realizada en el blockchain de Bitcoin, permiten a
          esta solución tecnológica realizar análisis que eventualmente puedan
          conectar direcciones vinculadas a actividades
          ilícitas específicas.
        </Paragraph>

        <Header3>Autorregulación y reporte a autoridades</Header3>

        <Paragraph>
          Buscamos constantemente oportunidades de mejora en nuestros procesos,
          incorporando las mejores prácticas internacionales a nuestro exchange.
          Si bien en algunos de los países donde operamos no calificamos
          legalmente como sujetos obligados a reportar ante las Unidades de
          análisis o Inteligencia Financiera (UIF) respectivas, nosotros
          voluntariamente nos hemos sometido a ciertas normas atingentes a las
          características de nuestro negocio, actuando de facto como un sujeto
          obligado, yendo más allá de lo que nos exige la regulación. Es por lo
          mismo que cada transacción inusual o actuar sospechoso en nuestra
          plataforma es analizado, solicitando información adicional al usuario
          para aclarar las conductas alertadas y - en caso de configurarse los
          supuestos y características exigidas por cada normativa - se reporta
          el caso a la UIF correspondiente.
        </Paragraph>
      </Styled.ContentBoxedContainer>

      {/* <Styled.SectionWave src={waveBlueUp}/> */}

      <ReadyForTheFuture />
      
      <Helmet>
        <meta charSet="utf-8" />
        <title> Prácticas y Estándares de AML / KYC | Fëanor</title>
        <meta
          name="description"
          content="Prácticas y Estándares de AML / KYC de feanor.io, el primer exchange de bitcoins legal en Panamá."
        />
      </Helmet>
    </>
  );
};

export default withRouter(KYC);
