import React from "react";
import { Paragraph, Center, Subtitle, Bold } from "./styles";

const TreatmentContract = () => {
  return (
    <div style={{ color: "black" }} id="informacion">
      <Center>
        TÉRMINOS DE USO, TRATAMIENTO, ACCESO A LA INFORMACIÓN Y PROTECCIÓN DE
        DATOS
      </Center>
      <Paragraph>
        En <Bold> FËANOR CORPORATION </Bold>(adelante FËANOR), queremos que
        conozcas la manera en que recopilamos, usamos, compartimos y protegemos
        tu información. Cuando interactúas con <Bold> FËANOR </Bold>a través de
        sitios web, aplicaciones para dispositivos móviles, productos y
        servicios, usted otorga su consentimiento para que usemos la información
        que recopilamos o que usted proporciona de acuerdo con los términos de
        esta política de privacidad. Podemos cambiar o extender esta política de
        privacidad, por lo cual le recomendamos que la revise periódicamente.
      </Paragraph>
      <Paragraph>
        Nuestra empresa cumple con las normas de protección de datos personales
        establecidos por la Ley 81 de 2019, su reglamento definido en el Decreto
        Ejecutivo 295 de 2021, y demás normas concordantes de la República de
        Panamá. El cliente y usuario de la presente plataforma acepta que FËANOR
        utilice los datos proporcionados según lo establecido en el presente
        Protocolo de Políticas de Uso, Tratamiento, Acceso a la Información y
        Protección de Datos.
      </Paragraph>
      <Subtitle>Definiciones.</Subtitle>
      <Paragraph>
        Para efectos del presente Protocolo de Políticas de Uso, Tratamiento,
        Acceso a la Información y Protección de Datos, las expresiones tendrán
        el significado que se les atribuye a continuación:
        <Paragraph>
          1. Almacenamiento de datos: Conservación o custodia de datos en una
          base de datos de <Bold> FËANOR </Bold>o de terceros que estos
          subcontraten para una actividad autorizada por el Usuario.
        </Paragraph>
        <Paragraph>
          2. Consentimiento: Manifestación de la voluntad otorgada por usuario
          para que <Bold> FËANOR </Bold>recolecte, use, tenga acceso, trate y
          traspase todos los datos e información que el Usuario le proporcione,
          siempre y cuando lo haga según lo dispuesto en este Protocolo de
          Políticas de Uso, Tratamiento, Acceso a la Información y Protección de
          Datos y las normas concordantes de la República de Panamá.
        </Paragraph>
        <Paragraph>
          3. FEÄNOR CORPORATION, S.A., sociedad anónima debidamente inscrita al
          Folio No. 155706843, de la Sección de Mercantil del Registro Público
          de la República de Panamá, con domicilio en Costa del Este, PH Torre
          Panamá, Piso 20, Oficina 20B, República de Panamá, (en adelante, el
          "FËANOR").
        </Paragraph>
        <Paragraph>
          4. Tratamiento de Datos: Cualquier operación o procedimiento técnico
          que le permita a <Bold> FËANOR </Bold>recolectar, almacenar, grabar,
          organizar, elaborar, seleccionar, extraer, confrontar, interconectar,
          asociar, disociar, comunicar, ceder, intercambiar, transferir,
          transmitir o cancelar datos del Usuario o utilizarlos en cualquier
          otra forma
        </Paragraph>
        <Paragraph>
          5. Usuario: Cliente o persona que utilice los servicios de{" "}
          <Bold> FËANOR </Bold>o que le otorgue sus datos.
        </Paragraph>
      </Paragraph>
      <Subtitle>¿Qué tipo de información recopilamos?</Subtitle>
      <Paragraph>
        1. Datos personales: Cualquier información concerniente a personas
        naturales, que las identifica o las hace identificables.
      </Paragraph>
      <Paragraph>
        2. Datos sensibles: Aquel que se refiera a la esfera íntima de su
        titular, o cuya utilización indebida pueda dar origen a discriminación o
        conlleve un riesgo grave para este. De manera enunciativa, se consideran
        sensibles los datos personales que puedan revelar aspectos como origen
        racial o étnico; creencias o convicciones religiosas, filosóficas y
        morales; afiliación sindical; opiniones políticas; datos relativos a la
        salud, a la vida, a la preferencia u orientación sexual, datos genéticos
        o datos biométricos, entre otros, sujetos a regulación y dirigidos a
        identificar de manera unívoca a una persona natural.
      </Paragraph>
      <Paragraph>
        3. Datos biométricos: Datos personales obtenidos a partir de un
        tratamiento técnico específico, relativos a las características físicas,
        fisiológicas o conductuales de una persona natural que permitan o
        confirmen la identificación única de dicha persona.
      </Paragraph>
      <Paragraph>
        4. La información de su dispositivo móvil (ejemplo: modelo de
        dispositivo, versión de sistema operativo, fecha y hora del dispositivo,
        identificadores de dispositivos únicos, información de red de telefonía
        móvil).
      </Paragraph>
      <Paragraph>
        5. La forma en que usa nuestros sitios y aplicaciones para dispositivos
        móviles, términos de búsqueda, páginas de nuestras aplicaciones para
        dispositivos móviles que visitas y desempeño de las aplicaciones.
      </Paragraph>
      <Paragraph>
        6. Información de los medios de comunicación (por ejemplo, participación
        publicitaria en redes sociales, digitales y de difusión).
      </Paragraph>
      <Paragraph>7. Dirección IP.</Paragraph>
      <Paragraph>
        8. Designaciones de estatus especial (por ejemplo: si eres jubilado,
        pensionado, tu profesión, etc.)
      </Paragraph>
      <Paragraph>9. Preferencias de idioma.</Paragraph>
      <Paragraph>
        10. Cualquier otra información que sea requerida y autorizada ya sea por
        ti o por la legislación vigente, para poder brindarte nuestros
        servicios.
      </Paragraph>
      <Paragraph>
        Si optas por no proporcionar información, es posible que no podamos
        brindarte nuestros productos, servicios o la información que solicitas.
      </Paragraph>

      <Subtitle>¿Cómo recopilamos tu información?</Subtitle>
      <Paragraph>
        1. Tu nos la proporcionas: Recopilamos información que tu ingresas en
        nuestros canales digitales, sucursales y/o oficinas.
      </Paragraph>
      <Paragraph>
        2. Recopilación de información automatizada: Es posible que vinculemos
        la información recopilada de forma automática con información que ya
        tenemos tuya en nuestra base de datos, a fin de identificarlo como un
        usuario único de FËANOR. Esto nos permite ofrecerte una experiencia
        personalizada independientemente de cómo interactúes con nosotros: en
        línea, en una sucursal, por dispositivo móvil, etc. Para ofrecer al
        usuario la mejor experiencia posible, también usamos tecnologías de
        recopilación de información automatizada a efectos de notificación y
        análisis. Examinamos parámetros como, por ejemplo, cómo interactúas en
        nuestro sitio web, en nuestras sucursales y en nuestras aplicaciones
        para dispositivos móviles, el rendimiento de nuestras iniciativas de
        mercadeo y su respuesta a esas iniciativas de mercadeo.
      </Paragraph>
      <Paragraph>
        3. Recopilación de información automatizada por parte de terceros:
        Nosotros y nuestros proveedores de servicios utilizamos identificadores
        anónimos, como cookies y otras tecnologías, para recopilar y almacenar
        ciertos tipos de información.
      </Paragraph>
      <Paragraph>
        4. Widgets de redes sociales: Nuestros sitios y aplicaciones para
        dispositivos móviles tienen funciones relativas a redes sociales como
        los widgets. Estas funciones pueden recopilar información sobre ti, como
        tu dirección IP y la página que estés visitando en nuestro sitio. Pueden
        instalar una cookie o emplear otras tecnologías de seguimiento para
        lograr esto. Las funciones y widgets de las redes sociales pueden ser
        alojados por terceros. Sus interacciones con esas funciones se rigen por
        las políticas de privacidad de las empresas que las ofrecen.
      </Paragraph>
      <Paragraph>
        5. Información de ubicación de dispositivos móviles: Si utilizas un
        dispositivo móvil, tu dispositivo podría compartir información sobre su
        ubicación (cuando activa los servicios de localización) con nuestras
        páginas de Internet, aplicaciones para dispositivos móviles, servicios o
        demás proveedores de servicios.
      </Paragraph>

      <Subtitle>¿Qué hacemos con su información?</Subtitle>
      <Paragraph>
        Cuando adquieres alguno de nuestros productos y/o servicios, ya sea en
        nuestras sucursales o en nuestros canales digitales, recopilamos la
        información personal que nos proporcionas. Cuando navegas por nuestra
        página web también recibimos automáticamente la dirección de protocolo
        de Internet (IP) de su computadora para proporcionarnos información que
        nos ayude a conocer su navegador y su sistema operativo.
      </Paragraph>
      <Paragraph>
        Esta información que voluntariamente nos proporcionas será utilizada
        exclusivamente para:
        <Paragraph>
          1. Gestionar el proceso del producto y/o servicio que estés
          contratando;
        </Paragraph>
        <Paragraph>
          2. Poder procesar transacciones en Plataforma de FËANOR;
        </Paragraph>
        <Paragraph>
          3. Poder atender tus solicitudes de información relativas a los
          productos y servicios que ofrecemos;
        </Paragraph>
        <Paragraph>
          4. Contactarte para poder monitorear el servicio que te prestamos;
        </Paragraph>
        <Paragraph>5. Gestionar servicios de suscripciones;</Paragraph>
        <Paragraph>
          6. Poder hacerte notificaciones sobre productos y/o servicios que
          puedes adquirir, descuentos y ofertas, que podrían interesarte;
        </Paragraph>
        <Paragraph>
          7. Enviarte por medios electrónicos: facturas electrónicas; documentos
          electrónicos para tu firma; notificaciones; mensajes electrónicos;
          anuncios personalizados y/o notificaciones de redes sociales.
          Ofrecerte funciones interactivas en nuestros canales digitales, tales
          como tu opinión o críticas del servicio o producto que adquiriste;
        </Paragraph>
        <Paragraph>
          8. Enviar comunicaciones de mercadeo y demás información sobre
          productos, servicios y promociones;
        </Paragraph>
        <Paragraph>
          9. Administrar promociones que te ofrecemos, encuestas y grupos de
          análisis, publicidad basada en tus intereses, así como realizar
          investigaciones y análisis;
        </Paragraph>
        <Paragraph>
          10. Confección de documentación de seguro que solicites y evaluarte;
        </Paragraph>
        <Paragraph>
          11. Cumplir con nuestras obligaciones contractuales o cualquier otra
          obligación que contraigas con FËANOR;
        </Paragraph>
        <Paragraph>12. Hacer valer nuestros derechos contractuales;</Paragraph>
        <Paragraph>
          13. Realizar notificaciones por razón de la relación contractual;
        </Paragraph>
        <Paragraph>
          14. Para realizar procesos de Debida Diligencia y de conocer a
          nuestros clientes que la legislación panameña y normas concordantes
          nos obligan a realizar; y
        </Paragraph>
        <Paragraph>
          15. Para cualquier otra actividad que sea autorizada por tu persona de
          forma escrita.
        </Paragraph>
      </Paragraph>
      <Paragraph>
        De igual manera el usuario, aun cuando no se le llegara a otorgar un
        producto o servicio, o en caso de que la relación contractual se haya
        vencido, autoriza a <Bold> FËANOR </Bold>para utilizar los datos
        proporcionados para: (i) Envío por cualquier canal de material
        publicitario y de mercadeo; (ii) Solicitud de actualización de datos;
        (iii) Para fines de investigación, estudios o estadísticas de la empresa
        y del mismo grupo económico.
      </Paragraph>
      <Paragraph>
        <Bold> FËANOR </Bold>y su personal que tenga acceso a tu información la
        manejará de manera confidencial, aun cuando haya cesado la relación con
        el usuario.
      </Paragraph>
      <Paragraph>
        <Bold> FËANOR </Bold>y su personal que tenga acceso a tu información la
        manejará de manera confidencial, aun cuando haya cesado la relación con
        el usuario.
      </Paragraph>
      <Paragraph>
        <Bold> FËANOR </Bold>no vende por ningún motivo nuestras bases de datos.
      </Paragraph>

      <Subtitle>¿Cómo nos das tu Consentimiento?</Subtitle>
      <Paragraph>
        Cuando nos proporcionas información, ya sean datos confidenciales,
        sensitivos o datos biométricos es porque aceptas que la recopilemos y la
        usemos por los motivos establecidos en el presente documento o en los
        que suscribas con FËANOR. Por nuestra parte, nos comprometemos contigo a
        sólo solicitar la información que requerimos según lo autorizado por el
        usuario en este Protocolo de Políticas de Uso, Tratamiento, Acceso a la
        Información y Protección de Datos y las normas concordantes de la
        República de Panamá.
      </Paragraph>

      <Subtitle>¿Cómo retirar su consentimiento?</Subtitle>
      <Paragraph>
        Escribiéndonos a la dirección de correo electrónico: atencion-al-cliente@feanor.io
        podrás solicitarnos tu derecho al acceso a la información, a la
        rectificación, cancelación, oposición y portabilidad sobre tus datos que
        mantenemos en nuestras bases de datos.
      </Paragraph>
      <Paragraph>
        Sólo escribimos y en un plazo no mayor de 10 días hábiles te enviaremos
        de forma clara, la información de tus datos que reposa en nuestra base
        de datos. En caso de que quieras ratificar algún dato, escríbenos al
        mismo correo electrónico y haremos la corrección en un término no mayor
        de 5 días hábiles. Por razón de las normas de protección de datos
        privados de la República de Panamá, no podrás solicitar información,
        rectificación, cancelación o bloqueo de tus datos personales cuando ello
        impida o entorpezca el debido trámite dentro de un proceso
        administrativo o judicial o por seguridad del Estado.
      </Paragraph>
      <Paragraph>
        No procederá la cancelación solicitada cuando el tratamiento sea
        necesario:
        <Paragraph>
          1. Para el cumplimiento de una obligación legal que requiera el
          tratamiento de datos que se aplique al responsable del tratamiento, o
          para el cumplimiento de una misión realizada en interés público o en
          el ejercicio de poderes públicos conferidos al responsable.
        </Paragraph>
        <Paragraph>
          2. Por razones de interés público en el ámbito de la salud pública.
        </Paragraph>
        <Paragraph>
          3. Con fines de archivo en interés público, fines de investigación
          científica o histórica o fines estadísticos, en la medida en que el
          derecho de cancelación pudiera hacer imposible u obstaculizar
          gravemente el logro de los objetivos de dicho tratamiento.
        </Paragraph>
        <Paragraph>
          4. Para la formulación, el ejercicio o la defensa de reclamaciones.
        </Paragraph>
      </Paragraph>

      <Subtitle>¿Cómo divulgamos tu información?</Subtitle>
      <Paragraph>
        Sólo divulgaremos tu información personal en los casos y forma previstos
        por la ley panameña de protección de datos y normas concordantes. No
        obstante lo anterior, al darnos tus datos personales, aceptas que
        podemos compartirlos con empresas de nuestro mismo grupo económico o con
        nuestros proveedores que requerimos para poder evaluar, gestionar,
        procesar y otorgar tu crédito o realizar cualquier otra actividad que
        nos autorices mediante el presente documento o cualquier otro que
        suscribas con nosotros en el futuro. Estas empresas podrían estar
        dedicadas a servicios de mercadeo, ser nuestros contadores, abogados,
        auditores, lista de historial de créditos, entre otras.
      </Paragraph>

      <Subtitle>
        Servidores, almacenamiento y transmisión de información
      </Subtitle>
      <Paragraph>
        Tus datos de contratación y transacciones se almacenan por el tiempo
        establecido por las normas panameñas. Después de que se complete dicho
        término, se borrará la información o se anonimizará.
      </Paragraph>

      <Subtitle>Políticas de Cookies</Subtitle>
      <Paragraph>
        Si usas nuestra página de internet, es importante que sepas que la misma
        utiliza cookies (archivos o ficheros que se descargan en tu ordenador o
        dispositivo que nos permiten, entre otras cosas, almacenar y recuperar
        información sobre tus hábitos de navegación e incluso en reconocer al
        usuario).
      </Paragraph>
      <Subtitle>Seguridad</Subtitle>
      <Paragraph>
        <Bold> FËANOR </Bold>almacena y procesa tu información personal
        aplicando medidas de seguridad y dando nuestro mejor esfuerzo para que
        no se pierda, utilice mal, acceda, revele, altere o destruya de manera
        inapropiada. Ciframos los canales de comunicación, realizamos respaldos
        electrónicos y la información es almacenada en lugares seguros con
        acceso restringido. Autorizamos el acceso a la información personal sólo
        para aquellos que lo requieran para cumplir con las responsabilidades de
        sus trabajos. Los contratos con nuestros colaboradores les informa que
        la información personas de nuestro cliente debe ser manejada de manera
        confidencial, aun cuando la relación contractual con el usuario haya
        finalizado.
        <Bold> FËANOR </Bold>no se hace responsable en caso de que un empleado
        de nuestro grupo haya cesado su relación laboral con nosotros e incumpla
        con su obligación de confidencialidad con tu información.
      </Paragraph>
      <Paragraph>
        <Bold> FËANOR </Bold>te informará en un término 72 horas, al igual que a
        las autoridades de la República de Panamá, en caso de que tenga
        conocimiento de alguna fuga de información que sufra nuestras bases de
        datos.
      </Paragraph>

      <Subtitle>Edad de consentimiento y veracidad de los datos</Subtitle>
      <Paragraph>
        Al utilizar este sitio, declaras que tienes al menos la mayoría de edad
        en la República de Panamá y nos ha dado tu consentimiento para el uso de
        tus datos personales. Además, declaras que los datos proporcionados son
        verdaderos y en caso contrario será suficiente razón para negar
        prestarte cualquier servicio o dar por terminado los mismos de manera
        anticipada y sin penalidad alguna para FËANOR.
      </Paragraph>

      <Subtitle>Cambios a esta política de privacidad</Subtitle>
      <Paragraph>
        Nos reservamos el derecho de modificar esta política de privacidad en
        cualquier momento, por lo tanto, revísela con frecuencia en nuestra
        página de internet. Los cambios y aclaraciones tendrán efecto
        inmediatamente después de su publicación en el sitio web. Si realizamos
        cambios sustanciales a esta política, te notificaremos aquí que se ha
        actualizado, para que sepas que información recopilamos, como la usamos
        y en qué circunstancias, si corresponde, usamos y/o divulgamos.
      </Paragraph>

      <Subtitle>Preguntas e información de contacto</Subtitle>
      <Paragraph>
        Si desea acceder, corregir o eliminar cualquier información personal que
        tengamos sobre usted, registrar una queja o necesita más información,
        puede comunicarse a través de correo electrónico: atencion-al-cliente@feanor.io.
      </Paragraph>
      <Paragraph>
        De igual manera, puedes interrumpir la recolección de información por
        parte de la página de internet de FËANOR. En el caso de nuestra página
        de internet, la función de ayuda de tu navegador debe contener
        instrucciones para configurar tu dispositivo de modo que acepte todas
        las cookies, te avise cuando se envía una cookie o nunca recibas
        cookies.
      </Paragraph>
    </div>
  );
};

export default TreatmentContract;
