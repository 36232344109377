import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Access history through withRouter
import { withRouter } from "react-router";
// Spinner Loader
// Styled components
import {
  Section,
  BTCPartnersBox,
  BTCPartners,
  BTCText,
  LogoPartner,
  Wrapper,
  Space,
  Heading2,
  BoxBitcoin,
  Card,
  CardHeading,
  Advantages,
  LoaderWrapper,
} from "styles/Styled";

import { PrimaryText, SecondaryText } from "styles/colorized";

import Styled from "./styles";
import {
  Header1,
  Header3,
  Paragraph,
  ParagraphSubtitle,
  TitleSection,
  Header1White,
  ParagraphSubtitleWhite,
  BlackContainer,
  TitleBlackSection,
  DescriptionBlackSection,
  SectionDividerWithTitle,
} from "styles/common/index.js";

import heroImg from "media/heros/about_us@2x_gold.png";

import { Helmet } from "react-helmet";

import ReadyForTheFuture from "ReadyForTheFuture";
import colors from "styles/common/colors";

const About = ({ history }) => {
  // Scroll to top on all pages
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollTop();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Styled.BlackContainer_aboutUs>
        <Styled.HeroLeft>
          <div style={{ marginBottom: "40px" }}>
            <Styled.TaglineAbout>
              <TitleBlackSection>Nosotros</TitleBlackSection>
            </Styled.TaglineAbout>
            <Styled.SubtaglineHome>
              <Styled.DescriptionBlackSection_aboutUs>
                Creemos que las criptomonedas serán una parte integral de
                nuestro futuro y por ello combinamos esfuerzos para lograrlo
                juntos.
              </Styled.DescriptionBlackSection_aboutUs>
            </Styled.SubtaglineHome>
          </div>
          <div style={{ marginBottom: "40px" }}>
            <Styled.TaglineAbout>
              <TitleBlackSection>Misión</TitleBlackSection>
            </Styled.TaglineAbout>
            <Styled.SubtaglineHome>
              <Styled.DescriptionBlackSection_aboutUs>
                Aportar acceso al mundo Financiero a travez de Blockchain.
              </Styled.DescriptionBlackSection_aboutUs>
            </Styled.SubtaglineHome>
          </div>
          <div style={{ marginBottom: "40px" }}>
            <Styled.TaglineAbout>
              <TitleBlackSection>Visión</TitleBlackSection>
            </Styled.TaglineAbout>
            <Styled.SubtaglineHome>
              <Styled.DescriptionBlackSection_aboutUs>
                Dar oportunidad a que cualquiera pueda estar en un sistema
                financiero abierto, sin barreras.
              </Styled.DescriptionBlackSection_aboutUs>
            </Styled.SubtaglineHome>
          </div>
        </Styled.HeroLeft>
        <Styled.HeroRight src={heroImg} alt="Network"></Styled.HeroRight>
      </Styled.BlackContainer_aboutUs>

      {/* <SectionDividerWithTitle>El equipo</SectionDividerWithTitle> */}

      <BlackContainer>
        <Styled.ContainerSectionHeader>
          <TitleSection style={{ color: colors.feanor_orange }}>
            “Las huellas de las personas que caminan juntas nunca se borran”{" "}
            <br></br>(Proverbio Africano){" "}
          </TitleSection>
        </Styled.ContainerSectionHeader>
      </BlackContainer>

      <ReadyForTheFuture />

      <Helmet>
        <meta charSet="utf-8" />
        <title> Nosotros | Fëanor</title>
        <meta
          name="description"
          content="El equipo detrás de Fëanor.com, la principal empresa Bitcoin en Perú."
        />
      </Helmet>
    </>
  );
};

export default withRouter(About);
