import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { HashLink as LinkHash } from "react-router-hash-link";
import { withRouter } from "react-router";
import {
  Logo,
  Nav,
  TopHeader,
  Menu,
  Item,
  // LogoutBtn,
  GeneralLinksContainer,
  // Sun,
  LinksContainer,
  MenuOptionTitle,
  MenuOptionSubtitle,
  MenuOption,
  SmallMenuItem,
  ItemBordered,
} from "./styles";
import { TopLine, BottomLine } from "styles/common/index.js";
import ToggleableNav from "components/navigation/ToggleableNav";
import logo from "media/logo-feanor.png";
import topLine from "media/lines/gold-line-top.png";
import bottomLine from "media/lines/gold-line-bottom.png";

import BuySellIcon from "media/icons/compra_venta_gold.png";
import DevelopersIcon from "media/icons/desarrolladores_icon.png";
import OTCIcon from "media/icons/otc_icon_gold.png";
import WalletIcon from "media/icons/wallet_icon_gold.png";

import { AuthContext } from "Auth";
import swal from "sweetalert";
import "./index.scss";
import feanorapi from "feanorapi.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";

const Header = ({ location, history }) => {
  const {
    currentToken,
    expiration,
    updateToken,
    updateExpiration,
  } = useContext(AuthContext);

  const handleLogOut = () => {
    swal({
      text: "Se ha cerrado sesión. Muchas gracias",
      button: false,
      timer: 3000,
    });
    updateToken("");
    updateExpiration(0);
    localStorage.setItem("token-feanor", "");
    history.push("/");
  };

  return (
    <>
      <TopHeader>
        <TopLine src={topLine} />
        <LinksContainer>
          <NavLink className="NavLogo" to="/">
            <Logo src={logo} alt="Fëanor logo" />
          </NavLink>
          <Nav className="show-desktop">
            <Menu>
              <GeneralLinksContainer>
                <Item>
                  <LinkHash
                    to="/tokenizacion-activos"
                    exact
                    activeClassName="selected-link"
                    className="nav-link"
                  >
                    Tokenización
                  </LinkHash>
                </Item>
                {/* <Item>
                  <LinkHash
                    to="/empresas#nuestros-servicios"
                    exact
                    activeClassName="selected-link"
                    className="nav-link"
                  >
                    Wallet crypto
                  </LinkHash>
                </Item> */}

                {/* <ItemBordered
                  href={`${feanorapi.webapp}/nft-market`}
                  activeClassName="selected-link"
                  className="nav-link"
                >
                  NFT Market
                </ItemBordered> */}

                <Item className="menu-dropdown">
                  <NavLink
                    to="/"
                    exact
                    activeClassName="selected-link"
                    className="nav-link"
                  >
                    Billetera
                  </NavLink>
                  <div class="dropdown-menu">
                    <ul>
                      {/* <LinkHash to="/#BuyBitcoin">
                        <li>
                          <div class="item-content">
                            <div class="left">
                              <img src={BuySellIcon} />
                            </div>
                            <MenuOption>
                              <div class="right">
                                <MenuOptionTitle>
                                  Compra y venta de Bitcoins{" "}
                                </MenuOptionTitle>
                              </div>
                              <div class="sm-description">
                                <MenuOptionSubtitle>
                                  Compra rápida vía deposito bancario
                                </MenuOptionSubtitle>
                              </div>
                            </MenuOption>
                          </div>
                        </li>
                      </LinkHash> */}

                      <NavLink to="/wallet">
                        <li>
                          <div class="item-content">
                            <div class="left">
                              <img src={WalletIcon} />
                            </div>

                            <MenuOption>
                              <div class="right">
                                <MenuOptionTitle>Personas - Tokenización</MenuOptionTitle>
                              </div>
                              {/* <div class="sm-description">
                                <MenuOptionSubtitle>
                                  description
                                </MenuOptionSubtitle>
                              </div> */}
                            </MenuOption>
                          </div>
                        </li>
                      </NavLink>

                      <NavLink to="/empresas">
                        <li>
                          <div class="item-content">
                            <div class="left">
                              <img src={OTCIcon} />
                            </div>
                            <MenuOption>
                              <div class="right">
                                <MenuOptionTitle>Empresas - Tokenización</MenuOptionTitle>
                                {/* <div class="sm-description">
                                  <MenuOptionSubtitle>
                                    description
                                  </MenuOptionSubtitle>
                                </div> */}
                              </div>
                            </MenuOption>
                          </div>
                        </li>
                      </NavLink>

                      {/* <NavLink to="/desarrolladores">
                        <li>
                          <div class="item-content">
                            <div class="left" style={{ marginRight: "15px"}}>
                              <img src={DevelopersIcon} />
                            </div>

                            <div class="right">
                              <div class="sm-title">Desarrolladores</div>
                              <div class="sm-description">
                                Documentación y API REST
                              </div>
                            </div>
                          </div>
                        </li>
                      </NavLink> */}
                    </ul>
                  </div>
                </Item>

                {/* <Item>
                  <LinkHash
                    to="/como-empezar"
                    exact
                    activeClassName="selected-link"
                    className="nav-link"
                  >
                    Cómo empezar
                  </LinkHash>
                </Item> */}

                <Item>
                  <LinkHash
                    to="/quienes-somos"
                    exact
                    activeClassName="selected-link"
                    className="nav-link"
                  >
                    Quienes somos
                  </LinkHash>
                </Item>

                {/* <Item>
                  <NavLink
                    to="/nosotros"
                    exact
                    activeClassName="selected-link"
                    className="nav-link"
                  >
                    Nosotros
                  </NavLink>
                </Item> */}

                {/* <Item>
                  <NavLink
                    to="/ayuda"
                    exact
                    activeClassName="selected-link"
                    className="nav-link"
                  >
                    Ayuda
                  </NavLink>
                </Item> */}
                {currentToken && !location.pathname.includes("app/profile") && (
                  <Item>
                    <NavLink
                      to="/app/profile"
                      activeClassName="selected-link"
                      className="nav-link"
                    >
                      Perfil
                    </NavLink>
                  </Item>
                )}
                {currentToken && location.pathname.includes("app/profile") && (
                  <Item>
                    <NavLink
                      to="/app/operation/new"
                      activeClassName="selected-link"
                      className="nav-link"
                    >
                      Comprar
                    </NavLink>
                  </Item>
                )}
                {currentToken ? (
                  <Item>
                    <NavLink
                      to="/auth/login"
                      activeClassName="selected-link"
                      className="nav-link"
                      type="button"
                      onClick={handleLogOut}
                    >
                      Cerrar sesión
                    </NavLink>
                  </Item>
                ) : (
                  <>
                    <Item className="menu-dropdown">
                      <NavLink
                        to="/"
                        exact
                        activeClassName="selected-link"
                        className="nav-link"
                      >
                        Iniciar sesión
                      </NavLink>
                      <div class="dropdown-menu">
                        <ul>
                          <SmallMenuItem
                            href={`${feanorapi.webapp}/auth/login_business`}
                          >
                            EMPRESAS
                          </SmallMenuItem>
                          <SmallMenuItem
                            href={`${feanorapi.webapp}/auth/login`}
                          >
                            PERSONAS
                          </SmallMenuItem>
                        </ul>
                      </div>
                    </Item>

                    {/* <Item>
                    <a
                      rel="noopener noreferrer"
                      href={`${feanorapi.webapp}/auth/login`}
                      class="nav-link"
                    >
                      Iniciar sesión
                    </a>
                  </Item> */}
                    <Item>
                      <a
                        href={`${feanorapi.webapp}/auth/register`}
                        class="nav-link"
                      >
                        Registrarse
                      </a>
                    </Item>
                  </>
                )}
              </GeneralLinksContainer>
            </Menu>
          </Nav>

          <ToggleableNav logout={handleLogOut} />
        </LinksContainer>

        {/* <Sun /> */}
        <BottomLine src={bottomLine} />
      </TopHeader>
    </>
  );
};

export default withRouter(Header);
