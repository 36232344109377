import React, { useEffect } from "react";
import { withRouter } from "react-router";
import Styled from "./styles";
import {
  BlackContainer,
  TitleBlackSection,
  SectionDividerWithTitle,
  Header1White,
} from "styles/common/index.js";
import heroImg from "media/heros/meet_bitcoin_gold.png";
import { Helmet } from "react-helmet";
import ContactUs from "pages/MeetFeanor/ContactUs";
import sergio from "images/sergio.jfif";
import walter from "images/walter.jfif";
// import belisario from "images/belisario.jfif";
import imgFabian from "images/fabian@2x.png";
// import gabriela from "images/gabriela.jpg";
// import ibrahim from "images/ibrahim.jpg";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faLinkedin);

const MeetBTC = ({ history }) => {
  // Scroll to top on all pages
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollTop();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Styled.BlackContainer_useCases>
        <Styled.HeroLeft>
          <div style={{ marginBottom: "40px" }}>
            <Styled.TaglineAbout>
              <TitleBlackSection>Conoce Fëanor</TitleBlackSection>
            </Styled.TaglineAbout>
            <Styled.SubtaglineHome>
              <Styled.DescriptionBlackSection_useCases>
                Somos una compañía enfocada en dar soluciones empresariales del
                sector Fintech con blockchain.
              </Styled.DescriptionBlackSection_useCases>
            </Styled.SubtaglineHome>
          </div>
          <div>
            <Styled.TaglineAbout>
              <TitleBlackSection>Nuestra meta</TitleBlackSection>
            </Styled.TaglineAbout>
            <Styled.SubtaglineHome>
              <Styled.DescriptionBlackSection_useCases>
                Estamos muy enfocados en ayudar a las empresas a poder darles un
                ingreso rápido a los servicios de tokenizacion de activos.
              </Styled.DescriptionBlackSection_useCases>
            </Styled.SubtaglineHome>
          </div>
          {/* <br />
          <div>
            <Styled.TaglineAbout>
              <TitleBlackSection>Misión</TitleBlackSection>
            </Styled.TaglineAbout>
            <Styled.SubtaglineHome>
              <Styled.DescriptionBlackSection_useCases>
                Aportar acceso al mundo Financiero a travez de Blockchain.
              </Styled.DescriptionBlackSection_useCases>
            </Styled.SubtaglineHome>
          </div>
          <br />
          <div>
            <Styled.TaglineAbout>
              <TitleBlackSection>Visión</TitleBlackSection>
            </Styled.TaglineAbout>
            <Styled.SubtaglineHome>
              <Styled.DescriptionBlackSection_useCases>
                Visión Dar oportunidad a que cualquiera pueda estar en un
                sistema financiero abierto, sin barreras.
              </Styled.DescriptionBlackSection_useCases>
            </Styled.SubtaglineHome>
          </div> */}
        </Styled.HeroLeft>
        <Styled.HeroRight src={heroImg}></Styled.HeroRight>
      </Styled.BlackContainer_useCases>

      <ContactUs showLineBottom={false}></ContactUs>

      <Header1White>Nuestro equipo </Header1White>

      <Styled.ContainerTextTeam>
        <Styled.DescriptionTeam>
          <Styled.SectionTextCentered>
            Con una combinada experiencia en pagos y en desarrollo de software,
            el equipo de Fëanor está preparado para tokenizar tus activos.
            Además, mejorar los estándares de la industria y potenciar el uso de
            la tecnología blockchain en latinoamérica
          </Styled.SectionTextCentered>
        </Styled.DescriptionTeam>
        <Styled.ContainerMembers>
          {/* <Styled.ContainerTeamMember>
            <Styled.ImageMember src={belisario} />
            <Styled.TeamMemberName>
              Belisario Castillo Saenz
            </Styled.TeamMemberName>
            <Styled.TeamMemberPosition>
              Chariman - Co Founder
            </Styled.TeamMemberPosition>

            <Styled.TeamMemberDescription>
              <Styled.LinkSocial
                target="_blank"
                href="https://www.linkedin.com/in/belisario-castillo-s%C3%A1enz-aa98222/e/"
              >
                <Styled.SocialIcon icon={faLinkedin} />
              </Styled.LinkSocial>
            </Styled.TeamMemberDescription>
          </Styled.ContainerTeamMember> */}
          <Styled.ContainerTeamMember>
            <Styled.ImageMember src={walter} />
            <Styled.TeamMemberName>Walter Alvarez Sack</Styled.TeamMemberName>
            <Styled.TeamMemberPosition>
              CFO - Co Founder
            </Styled.TeamMemberPosition>

            <Styled.TeamMemberDescription>
              <Styled.LinkSocial
                target="_blank"
                href="https://www.linkedin.com/in/walter-alvarez-sack-17662984/"
              >
                <Styled.SocialIcon icon={faLinkedin} />
              </Styled.LinkSocial>
            </Styled.TeamMemberDescription>
          </Styled.ContainerTeamMember>

          <Styled.ContainerTeamMember>
            <Styled.ImageMember src={imgFabian} />
            <Styled.TeamMemberName>Fabian Chiera</Styled.TeamMemberName>
            <Styled.TeamMemberPosition>
              CTO - Co Founder
            </Styled.TeamMemberPosition>

            <Styled.TeamMemberDescription>
              <Styled.LinkSocial
                target="_blank"
                href="https://www.linkedin.com/in/fabianchiera/"
              >
                <Styled.SocialIcon icon={faLinkedin} />
              </Styled.LinkSocial>
            </Styled.TeamMemberDescription>
          </Styled.ContainerTeamMember>

          <Styled.ContainerTeamMember>
            <Styled.ImageMember src={sergio} />
            <Styled.TeamMemberName>Sergio Terstusio</Styled.TeamMemberName>
            <Styled.TeamMemberPosition>
              Director Fëanor
            </Styled.TeamMemberPosition>
            <Styled.TeamMemberDescription>
              <Styled.LinkSocial
                target="_blank"
                href="https://www.linkedin.com/in/sergiotertusio/"
              >
                <Styled.SocialIcon icon={faLinkedin} />
              </Styled.LinkSocial>
            </Styled.TeamMemberDescription>
          </Styled.ContainerTeamMember>

          {/* <Styled.ContainerTeamMember>
            <Styled.ImageMember src={ibrahim} />
            <Styled.TeamMemberName>Ibrahim Adippe</Styled.TeamMemberName>
            <Styled.TeamMemberPosition>
              Gerente de operaciones
            </Styled.TeamMemberPosition>

            <Styled.TeamMemberDescription>
              <Styled.LinkSocial
                target="_blank"
                href="https://www.linkedin.com/in/iadippe13"
              >
                <Styled.SocialIcon icon={faLinkedin} />
              </Styled.LinkSocial>
            </Styled.TeamMemberDescription>
          </Styled.ContainerTeamMember> */}

          {/* <Styled.ContainerTeamMember>
            <Styled.ImageMember src={gabriela} />
            <Styled.TeamMemberName>Gabriela Bravo</Styled.TeamMemberName>
            <Styled.TeamMemberPosition>
              Cumplimiento Fëanor
            </Styled.TeamMemberPosition>

            <Styled.TeamMemberDescription>
              <Styled.LinkSocial
                target="_blank"
                href="https://www.linkedin.com/in/daneccy-gabriela-bravo-qui%C3%B1ones-07630148/"
              >
                <Styled.SocialIcon icon={faLinkedin} />
              </Styled.LinkSocial>
            </Styled.TeamMemberDescription>
          </Styled.ContainerTeamMember> */}
        </Styled.ContainerMembers>
      </Styled.ContainerTextTeam>

      <SectionDividerWithTitle>Estamos contigo</SectionDividerWithTitle>

      <BlackContainer>
        <Styled.SectionTextCentered light={true}>
          Entendemos del riesgo que conlleva estas operaciones, ya que parte del
          equipo es de la Banca tradicional, por eso tenemos Oficiales de
          Cumplimiento, y hacemos toda la Debida Diligencia, para prevenir y
          limitar la incertidumbre.
        </Styled.SectionTextCentered>
      </BlackContainer>

      {/* <SectionDividerWithTitle>Nuestro equipo</SectionDividerWithTitle> */}

      <BlackContainer></BlackContainer>

      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {" "}
          Conoce Fëanor - Aprende todo sobre el dinero del futuro | Fëanor
        </title>
        <meta
          name="description"
          content="Esta guía te enseñará todo lo necesario: qué es Bitcoin, Cómo funciona, su valor y cuáles son sus principales características."
        />
      </Helmet>
    </>
  );
};

export default withRouter(MeetBTC);
