let endpoint = "";
let webapp = "";

switch (process.env.REACT_APP_ENV_TYPE) {
  case 'development':
    endpoint = process.env.REACT_APP_FEANOR_API_DEV
    webapp = process.env.REACT_APP_FEANOR_WEBAPP
    break;
  case 'testing':
    endpoint = process.env.REACT_APP_FEANOR_API_TEST
    webapp = process.env.REACT_APP_FEANOR_WEBAPP
    break;
  case 'production':
    endpoint = process.env.REACT_APP_FEANOR_API_PROD
    webapp = process.env.REACT_APP_FEANOR_WEBAPP
    break;
  default:
    endpoint = process.env.REACT_APP_FEANOR_API_DEV
    webapp = process.env.REACT_APP_FEANOR_WEBAPP
}

export default {endpoint, webapp};
