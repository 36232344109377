import React, { useEffect } from "react";
// Access history through withRouter
import { withRouter } from "react-router";
// Spinner Loader

import { PrimaryText } from "styles/colorized";

import Styled from "./styles";

import { Helmet } from "react-helmet";
import ReadyForTheFuture from "ReadyForTheFuture";
// import UseTerms from "./UseTerms/TreatmentContract";
import AllInOne from "./components/AllInOne";

const Terms = ({ history }) => {
  // Scroll to top on all pages
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollTop();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Styled.AboutContainer>
        <Styled.ContainerSectionHeader>
          <Styled.TaglineAbout>
            <PrimaryText>Términos y condiciones</PrimaryText>
          </Styled.TaglineAbout>
          <Styled.SubtaglineHome>
            <PrimaryText>
              A continuación encontrarás los Términos y Condiciones del sitio.{" "}
              <br></br>
              Forman un contrato entre feanor.io y el usuario.
            </PrimaryText>
          </Styled.SubtaglineHome>
        </Styled.ContainerSectionHeader>
      </Styled.AboutContainer>

      <Styled.ContentBoxedContainer>
        {/* <UseTerms /> */}
        <AllInOne/>
      </Styled.ContentBoxedContainer>

      <ReadyForTheFuture />

      <Helmet>
        <meta charSet="utf-8" />
        <title> Términos del Uso | Fëanor</title>
        <meta
          name="description"
          content="Términos de Uso de feanor.io, el primer exchange de bitcoins legal en Panamá."
        />
      </Helmet>
    </>
  );
};

export default withRouter(Terms);
