import React from "react";
import { Paragraph, Center, Subtitle, Bold, Underline } from "./styles";
const PlatformContract = () => {
  return (
    <div style={{ color: "black" }} id="tarjeta">
      <Center>
        CONTRATO PARA LA EMISIÓN Y USO DE TARJETA DE CRÉDITO PREPAGO
      </Center>
      <Paragraph>Entre los suscritos, a saber:</Paragraph>
      <Paragraph>
        <Bold>FEÄNOR CORPORATION, S.A.,</Bold> sociedad anónima debidamente inscrita al Folio
        No. 155706843, de la Sección de Mercantil del Registro Público de la
        República de Panamá, con domicilio en Costa del Este, PH Torre Panamá,
        Piso 20, Oficina 20B, República de Panamá, empresa que provee una
        plataforma de servicios para facilitar transacciones de activos
        digitales a nivel global, por sí o a través de terceros (en adelante, el
        &quot;<Bold>FËANOR</Bold>&quot;), por una parte y, por la otra,
      </Paragraph>
      <Paragraph>
        <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold>, es una persona natural o jurídica, que ha cumplido
        con la debida diligencia requerida por <Bold>FËANOR</Bold> y, voluntariamente,
        acuerda celebrar este Contrato
      </Paragraph>
      <Subtitle>DEFINICIONES:</Subtitle>
      <Paragraph>
        <Paragraph>
          a) <Bold><Underline>AFILIACIÓN Y USO DE LA PLATAFORMA</Underline></Bold>: Comisión anual de US$225.00 por
          el servicio de la PLATAFORMA DE <Bold>FËANOR</Bold> o LA PLATAFORMA.
        </Paragraph>
        <Paragraph>
          b) <Bold><Underline>COMISIONES</Underline></Bold>: Comisiones que deberá pagar EL TARJETA HABIENTE por el
          uso de la Tarjeta de Crédito Prepago que serán publicadas en LA
          PLATAFORMA de <Bold>FËANOR</Bold>.
        </Paragraph>
        <Paragraph>
          c) <Bold><Underline>CONTRATO DE PLATAFORMA DE <Bold>FËANOR</Bold></Underline></Bold>: Contrato que regula la Plataforma
          de <Bold>FËANOR</Bold>.
        </Paragraph>
        <Paragraph>
          d) <Bold><Underline>CONVENIO DE USO DE MARCA: <Bold>FËANOR</Bold></Underline></Bold> declara que, por virtud de
          convenios celebrados con VERSATEC, está facultado para emitir tarjetas
          con la denominación comercial de registro internacional VISA.
        </Paragraph>
        <Paragraph>
          e) <Bold><Underline>CUENTA</Underline></Bold>: Cuenta en la Plataforma de <Bold>FËANOR</Bold> que deberá mantener
          abierta durante la vigencia de este Contrato, a fin de depositar, como
          mínimo, las sumas de dinero equivalentes a la totalidad de las
          operaciones que se proponga realizar y de los cargos en que se vaya a
          incurrir EL CLIENTE por la utilización de la <Bold>TARJETA PREPAGADA</Bold>.
        </Paragraph>
        <Paragraph>
          f) <Bold><Underline>ESTADO DE CUENTA</Underline></Bold>: Documento elaborado por <Bold>FËANOR</Bold>, que contiene la
          descripción de las distintas operaciones en las que se ha utilizado la
          <Bold>TARJETA PREPAGADA</Bold>, el cual será comunicado a <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> en la
          forma pactada y en cumplimiento de las exigencias previstas en la Ley
          81 de diciembre de 2009.
        </Paragraph>
        <Paragraph>
          g) <Bold><Underline>EMPRESA AFILIADA</Underline></Bold>: Establecimiento comercial, financiero, industrial
          o de servicios, con quien se ha suscrito un convenio de afiliación
          para que <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> pueda utilizar la <Bold>TARJETA PREPAGADA</Bold>
          emitida por <Bold>FËANOR</Bold> para la adquisición de dinero o para el
          arrendamiento o adquisición de bienes o servicios.
        </Paragraph>
        <Paragraph>h) <Bold><Underline>FËANOR</Underline></Bold>: FEÄNOR CORPORATION, S.A.</Paragraph>
        <Paragraph>
          i) <Bold><Underline>PLATAFORMA DE FËANORo LA PLATAFORMA</Underline></Bold>: Plataforma que se accede en
          el world wide web (www), que opera bajo el dominio: www.feanor.io.
        </Paragraph>
        <Paragraph>
          j) <Bold><Underline><Bold>TARJETAHABIENTE</Bold></Underline></Bold>: Persona que suscribe un CONTRATO de Emisión y Uso
          de Tarjeta de Crédito Prepago con <Bold>FËANOR</Bold>, a cuyo nombre se expide una
          Tarjeta de Crédito Prepago y quien será responsable por los saldos y
          obligaciones que se generen por el uso de dicha Tarjeta de Crédito
          Prepago.
        </Paragraph>
        <Paragraph>
          k) <Bold><Underline>TARJETA DE CRÉDITO PREPAGO o <Bold>TARJETA PREPAGADA</Bold></Underline></Bold>: Es una tarjeta
          elaborada con material plástico, expedida a favor de EL
          <Bold>TARJETAHABIENTE</Bold>, que tienen un saldo de dinero asociado a una cuenta
          que <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> mantiene con la Plataforma de <Bold>FËANOR</Bold>.
        </Paragraph>
        <Paragraph>
          l) <Bold><Underline><Bold>TARJETAHABIENTE</Bold> ADICIONAL</Underline></Bold>: Persona o personas designadas y
          autorizadas expresamente por escrito para recibir una TARJETA
          PREPAGADA a su nombre para ser usada dentro del mismo límite aprobado
          para <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold>.
        </Paragraph>
        <Paragraph>
          m) <Bold><Underline>VERSATEC</Underline></Bold>: FINANCIA CREDIT, S.A., sociedad inscrita a la Ficha No.
          624944, Documento No. 1384907, del Registro Público de la República de
          Panamá.
        </Paragraph>
      </Paragraph>
      <Subtitle>DERECHO DE PROPIEDAD:</Subtitle>
      <Paragraph>
        <Bold>FËANOR</Bold> emitirá y entregará una <Bold>TARJETA PREPAGADA</Bold> a favor del
        <Bold>TARJETAHABIENTE</Bold>. En la tarjeta aparecerá el número de tarjeta asignado a
        ésta, el nombre y la firma del <Bold>TARJETAHABIENTE</Bold>, la fecha de expiración,
        los emblemas de VISA y de <Bold>FËANOR</Bold>, y cualesquiera otros emblemas que
        <Bold>FËANOR</Bold> determine.
      </Paragraph>
      <Paragraph>
        La <Bold>TARJETA PREPAGADA</Bold> es intransferible y será en todo momento de
        propiedad de <Bold>FËANOR</Bold> quien podrá, en cualquier momento y sin necesidad de
        causa específica revocar o suspender el derecho de usar dicha TARJETA
        PREPAGADA, sin necesidad de previo aviso. Revocado el derecho de uso de
        la <Bold>TARJETA PREPAGADA</Bold>, la misma no podrá ser utilizada por el
        <Bold>TARJETAHABIENTE</Bold>. En este caso, el <Bold>TARJETAHABIENTE</Bold> se obligan
        expresamente a cancela a requerimiento de <Bold>FËANOR</Bold>, la suma total de lo
        que le adeude o le llegue a adeudar.
      </Paragraph>
      <Subtitle>USO DE LA <Bold>TARJETA PREPAGADA</Bold></Subtitle>
      <Paragraph>
        El <Bold>TARJETAHABIENTE</Bold> previa presentación de la <Bold>TARJETA PREPAGADA</Bold> podrá
        obtener o adquirir bienes, servicios o adelantos de efectivo de aquellas
        personas naturales o jurídicas que formen parte del sistema de VISA
        INTERNACIONAL. La utilización de la <Bold>TARJETA PREPAGADA</Bold> para cualquier
        otro fin distinto al señalado dará lugar a la revocación de la TARJETA
        PREPAGADA, sin responsabilidad alguna para <Bold>FËANOR</Bold>.
      </Paragraph>
      <Paragraph>
        El <Bold>TARJETAHABIENTE</Bold> cada vez que hagan uso de la <Bold>TARJETA PREPAGADA</Bold>,
        suscribirá un pagaré a la vista por el importe de los bienes, servicios
        o adelantos adquiridos, pagaré este que deberá ser entregado a la
        persona o empresa vendedora.
      </Paragraph>
      <Paragraph>
        El <Bold>TARJETAHABIENTE</Bold> acepta que, dados los adelantos técnicos y las
        modalidades novedosas de utilización de la <Bold>TARJETA PREPAGADA</Bold>, dichos
        pagarés pueden presentar las siguientes características:
        <Paragraph>
          a) Que no contengan la firma del <Bold>TARJETAHABIENTE</Bold> <Bold>TITULAR</Bold> o de EL (LOS)
          <Bold>TARJETAHABIENTE</Bold>(S) ADICIONAL(ES) por virtud de compras efectuadas por
          éste (éstos) por teléfono, correo o por internet, en cuyo caso los
          importes por el costo del producto o servicio contratado no tendrán
          que necesariamente estar suscritos por el <Bold>TARJETAHABIENTE</Bold> <Bold>TITULAR</Bold> o
          por EL (LOS) <Bold>TARJETAHABIENTE</Bold>(S) ADICIONAL(ES).
        </Paragraph>
        <Paragraph>b) Que tengan defectos menores de impresión.</Paragraph>
        <Paragraph>
          c) Que los cargos correspondientes a los bienes o servicios adquiridos
          y a los adelantos de efectivo efectuados sean emitidos de forma manual
          o por medios electrónicos, lo cual, en el último supuesto, podrá
          causar inclusive que no se expida un pagaré o letra a la vista.
        </Paragraph>
      </Paragraph>

      <Subtitle>RESPONSABILIDAD DE PAGO POR BIENES Y SERVICIOS</Subtitle>
      <Paragraph>
        <Bold>FËANOR</Bold> se obliga a cancelar el importe de las adquisiciones de bienes,
        servicios y adelantos de efectivo que efectúe(n) el <Bold>TARJETAHABIENTE</Bold>
        mediante la utilización de la <Bold>TARJETA PREPAGADA</Bold>, ante aquellas personas
        o establecimientos comerciales que formen parte del sistema
        internacional de la tarjeta de crédito VISA, siempre y cuando se haya
        dado cumplimiento a los términos y condiciones contenidos en el presente
        contrato. En tal sentido, se entenderá que los pagos que efectúe <Bold>FËANOR</Bold>
        por la utilización de la <Bold>TARJETA PREPAGADA</Bold>, son por cuenta del
        <Bold>TARJETAHABIENTE</Bold> quien estará obligado a pagar dichas sumas a <Bold>FËANOR</Bold>, en
        la forma que se estipule en este contrato.
      </Paragraph>
      <Paragraph>
        <Bold>FËANOR</Bold> no se hace responsable por el estado, precio, cantidad o calidad
        de los bienes, mercancías o servicios adquiridos por <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold>
        y/o EL(LOS) <Bold>TARJETAHABIENTE</Bold>(S) ADICIONAL(ES) mediante el uso de la
        <Bold>TARJETA PREPAGADA</Bold> en las empresas y establecimientos afiliados, de
        manera que cualquier reclamo o disputa en este sentido deberá dirigirse
        o solucionarse exclusivamente con el vendedor o la Empresa o
        Establecimiento donde se efectuó la adquisición. El incumplimiento por
        parte de la Empresa o Establecimiento Afiliado o el Vendedor de ninguna
        manera afectará a <Bold>FËANOR</Bold>, quien tendrá derecho a cobrar las sumas
        respectivas reflejadas en el Estado de Cuenta de <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold>.
      </Paragraph>
      <Paragraph>
        En caso de devolución o reducción del precio de la mercancía o servicio,
        el derecho de <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> y EL(LOS) <Bold>TARJETAHABIENTE</Bold>(S)
        ADICIONAL(ES) según se trate, se limita a exigir del Vendedor o
        Establecimiento Afiliado una copia de la nota de crédito
        correspondiente, y si el ajuste no apareciera en el siguiente Estado de
        Cuenta deberá hacer la reclamación correspondiente al Vendedor o
        Establecimiento Afiliado.
      </Paragraph>
      <Paragraph>
        <Bold>FËANOR</Bold> no es responsable por la pérdida o daños causados por el uso
        fraudulento no autorizado de la <Bold>TARJETA PREPAGADA</Bold> bajo la cuenta de EL
        <Bold>TARJETAHABIENTE</Bold> ni por el uso ilícito de su código secreto (<Bold>PIN</Bold>). Se
        presume concluyentemente entre <Bold>FËANOR</Bold> y <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> que toda
        transacción realizada a través de la <Bold>TARJETA PREPAGADA</Bold> ha sido efectuada
        y autorizada por <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold>.
      </Paragraph>
      <Paragraph>
        <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> será responsable por aquellas facturas en las cuales,
        aún sin su firma o siendo ésta diferente, el comprobante de venta o
        pagaré contenga la impresión de los datos grabados en relieve de la
        <Bold>TARJETA PREPAGADA</Bold>, realizada mediante la impresora del vendedor,
        conservando la obligación de corroborar con su estado de cuenta dicha
        información para poder presentar cualquier reclamo. En igual forma, EL
        <Bold>TARJETAHABIENTE</Bold> será responsable frente a <Bold>FËANOR</Bold>, aún en aquellos casos
        en que en la factura o pagaré que elabora el vendedor aparezca ilegible
        los datos de <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> que acredite la transacción. En este
        último caso, se tendrá como fecha de la factura, la fecha del depósito
        del pagaré efectuado por el vendedor.
      </Paragraph>
      <Paragraph>
        <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> será responsable por las compras que realice tanto en
        sitios seguros como no seguros en Internet y deberá velar por su
        información de seguridad para que no sea expuesta en sitios públicos en
        Internet, así como redes de comunicación internas.
      </Paragraph>

      <Subtitle>CUENTA:</Subtitle>
      <Paragraph>
        <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> determinará el límite establecido para la utilización
        de la <Bold>TARJETA PREPAGADA</Bold> a su sola discreción, mediante las sumas que
        previamente depositen en su cuenta y sean aceptadas por <Bold>FËANOR</Bold>, quien se
        reserva el derecho de establecer, de tiempo en tiempo, límites a las
        sumas de dinero que <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> transfieran, carguen o depositen
        a la CUENTA.
      </Paragraph>
      <Paragraph>
        Igualmente queda convenido que <Bold>FËANOR</Bold> podrá establecer restricciones a
        la frecuencia de los retiros y cantidad de dinero en efectivo que
        realice <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> de la CUENTA.
      </Paragraph>
      <Paragraph>
        En caso de cierre de la CUENTA, los fondos disponibles EL
        <Bold>TARJETAHABIENTE</Bold> si los hubiere, le serán entregados mediante cheque o
        mediante una moneda estable que <Bold>FËANOR</Bold> determine.
      </Paragraph>
      <Paragraph>
        <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> se obliga a depositar inmediatamente, las veces que
        sea necesario, las cantidades que excediesen el monto disponible en su
        CUENTA, sin necesidad de notificación alguna por parte de <Bold>FËANOR</Bold>, pues
        es obligación de <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> es conocer y cubrir inmediatamente
        dichos excesos. Todos los depósitos y/o pagos que deba realizar EL
        <Bold>TARJETAHABIENTE</Bold> conforme al presente contrato, los efectuarán en
        Plataforma de <Bold>FËANOR</Bold>, o por medio de los sistemas electrónicos
        disponibles en <Bold>FËANOR</Bold>, o en el lugar que <Bold>FËANOR</Bold> designe de tiempo en
        tiempo, en Dólares de los Estados Unidos de América. En caso de que la
        <Bold>TARJETA PREPAGADA</Bold> sea utilizada para adquirir bienes o servicios
        facturados en moneda diferente al Dólar, la conversión a Dólares se
        realizará conforme a lo que establezca <Bold>VISA INTERNACIONAL</Bold> sobre el
        particular, sin lugar a reclamos por parte de <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold>.
      </Paragraph>
      <Paragraph>
        <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> entiende y acepta que el acreditamiento de fondos a
        la CUENTA tiene un tiempo de procesamiento de acuerdo con la fuente de
        fondos utilizada de veinticuatro horas, que deberán tomar en cuenta para
        la utilización de la <Bold>TARJETA PREPAGADA</Bold>.
      </Paragraph>
      <Subtitle>ESTADO DE CUENTA:</Subtitle>
      <Paragraph>
        <Bold>FËANOR</Bold> elaborará un estado de cuenta mensual en el cual detallará las
        operaciones efectuadas por <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> y lo enviará a través del
        medio seleccionado por <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> dentro de las opciones
        planteadas por <Bold>FËANOR</Bold>. Este estado de cuenta mensual será enviado dentro
        de los siguientes diez (10) días hábiles desde la fecha de cierre de
        estado de cuenta a la dirección registrada del <Bold>TARJETAHABIENTE</Bold> <Bold>TITULAR</Bold>
        en <Bold>FËANOR</Bold> o en la CUENTA de <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> en <Bold>FËANOR</Bold>.
      </Paragraph>
      <Paragraph>
        Dicho estado de cuenta incluirá los cargos mensuales que se causen, así
        como cualquier otro gasto que deba sufragar el <Bold>TARJETAHABIENTE</Bold> según el
        presente Contrato. Transcurridos veinte (30) días calendario contados
        desde la emisión del estado de cuenta o publicación (según sea el caso)
        del referido estado de cuenta sin que el <Bold>TARJETAHABIENTE</Bold> <Bold>TITULAR</Bold> lo
        hubiere objetado por escrito, el mismo se tendrá por aceptado por el
        <Bold>TARJETAHABIENTE</Bold> <Bold>TITULAR</Bold>, quien no podrá presentar reclamo alguno a
        <Bold>FËANOR</Bold> con posterioridad a dicho plazo, por lo que, en consecuencia, los
        saldos allí contenidos se tendrán por reconocidos y serán definitivos.
        <Bold>FËANOR</Bold> podrá variar, con treinta (30) días calendario de aviso para
        ello, la fecha de expedición o de envió de los estados de cuenta.
      </Paragraph>
      <Paragraph>
        <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> se obliga a mantener a <Bold>FËANOR</Bold> informado sobre sus
        cambios en lo referente a dirección residencial, comercial, apartado
        postal, correo electrónico, y números telefónicos quedando entendido que
        cualquier envío hecho a la última dirección registrada en <Bold>FËANOR</Bold> se
        entenderá como realizado de manera válida.
      </Paragraph>
      <Paragraph>
        Se entenderá por reconocido y legalmente aceptado el Estado de Cuenta
        por parte de <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> si éste no lo objetase mediante nota
        escrita debidamente entregada a <Bold>FËANOR</Bold> dentro de los treinta (30) días
        calendario contados a partir de la emisión del Estado de Cuenta por
        parte de <Bold>FËANOR</Bold>. Pasado este período de tiempo, <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> no
        tendrá derecho a presentar reclamo a <Bold>FËANOR</Bold> dándose por definitivo el
        saldo que indica el Estado de Cuenta.
      </Paragraph>

      <Subtitle>
        OBLIGACIONES DE <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> Y EL (LOS) <Bold>TARJETAHABIENTE</Bold>S
        ADICIONALES
      </Subtitle>
      <Paragraph>
        <Paragraph>
          a) Resguardar la <Bold>TARJETA PREPAGADA</Bold> con la debida diligencia. EL
          <Bold>TARJETAHABIENTE</Bold> debe mantener en todo momento su <Bold>TARJETA PREPAGADA</Bold> y
          el código secreto (<Bold>PIN</Bold>) asociado a ella exclusivamente en su poder.
        </Paragraph>
        <Paragraph>
          b) Mantener y custodiar su <Bold>PIN</Bold> separado de la <Bold>TARJETA PREPAGADA</Bold>.
        </Paragraph>
        <Paragraph>
          c) No permitir el uso de su <Bold>TARJETA PREPAGADA</Bold> ni de su código secreto
          (<Bold>PIN</Bold>) a terceras personas. la <Bold>TARJETA PREPAGADA</Bold> es de uso personal.
        </Paragraph>
        <Paragraph>
          d) Presentar cédula de identidad personal o pasaporte al momento de
          realizar una transacción con la <Bold>TARJETA PREPAGADA</Bold> y no divulgar ni
          confiar a nadie su código secreto de acceso a los cajeros y a otros
          sistemas electrónicos.
        </Paragraph>
        <Paragraph>
          e) Realizar puntualmente los pagos por la utilización de la tarjeta de
          crédito con la debida diligencia.
        </Paragraph>
        <Paragraph>
          f) Verificar el importe y la veracidad de la información antes de
          firmar los comprobantes de pago.
        </Paragraph>
        <Paragraph>
          g) Solicitar y guardar los comprobantes de pago y demás documentos de
          compra de bienes y utilización de servicios hasta recibir el estado de
          cuenta y estar conforme con éste.
        </Paragraph>
        <Paragraph>
          h) Velar por el correcto uso de las tarjetas adicionales que solicite
          y autorice emitir.
        </Paragraph>
        <Paragraph>
          i) Procurar el mantenimiento, buen manejo y conservación del límite de
          crédito.
        </Paragraph>
        <Paragraph>
          j) Indicar a <Bold>FËANOR</Bold> la dirección postal o domicilio asignado a la
          <Bold>TARJETA PREPAGADA</Bold>, a efectos de que éste le remita los estados de
          cuenta o cualquier otra información o comunicación pertinente.
        </Paragraph>
        <Paragraph>
          k) Informar a <Bold>FËANOR</Bold> cuando no reciba el estado de cuenta en el plazo
          que este haya establecido.
        </Paragraph>
        <Paragraph>
          l) Verificar la tasa de interés y otros cargos que le aplique <Bold>FËANOR</Bold>,
          así como los procedimientos para hacer a tiempo sus reclamos sobre los
          productos y servicios que adquiera por medio de la tarjeta.
        </Paragraph>
        <Paragraph>
          m) Efectuar los reclamos en el plazo establecido en el contrato. EL
          <Bold>TARJETAHABIENTE</Bold> entiende y acepta que los reclamos serán manejados de
          acuerdo con los tiempos definidos para tales efectos por VISA
          INTERNACIONAL en sus reglamentos operativos.
        </Paragraph>
        <Paragraph>
          n) Informar de manera inmediata a <Bold>FËANOR</Bold> el robo, hurto o pérdida de
          la tarjeta.
        </Paragraph>
      </Paragraph>
      <Subtitle>PÉRDIDA, ROBO O HURTO DE LA <Bold>TARJETA PREPAGADA</Bold>:</Subtitle>
      <Paragraph>
        En caso de que la <Bold>TARJETA PREPAGADA</Bold> se pierda, sea hurtada o robada, EL
        <Bold>TARJETAHABIENTE</Bold> deberá notificar de inmediato a <Bold>FËANOR</Bold> por medio de los
        canales alternos que <Bold>FËANOR</Bold> tiene a su disposición las veinticuatro (24)
        horas del día, El <Bold>TARJETAHABIENTE</Bold> será ilimitadamente responsables de
        los consumos, adelantos de efectivo, quasi cash y demás usos realizados
        de la <Bold>TARJETA PREPAGADA</Bold> hasta el día siguiente hábil de dicha
        notificación. En caso de no hacerse la notificación de que trata este
        punto, la responsabilidad de <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> terminará el día del
        vencimiento de la <Bold>TARJETA PREPAGADA</Bold>. <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> será responsable
        de la debida utilización de la <Bold>TARJETA PREPAGADA</Bold> y en consecuencia no
        permitirá que personas no autorizadas hagan uso de ella. En caso de que
        lo anterior suceda, <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> <Bold>TITULAR</Bold> será responsable de los
        pagos dados.
      </Paragraph>
      <Paragraph>
        En los casos de pérdida, robo o hurto arriba mencionados, EL
        <Bold>TARJETAHABIENTE</Bold> se obliga a cumplir con lo siguiente:
        <Paragraph>
          a) Comunicar a <Bold>FËANOR</Bold>, por escrito o vía telefónica o a través de LA
          CUENTA, la pérdida, el hurto o robo de la <Bold>TARJETA PREPAGADA</Bold>
          inmediatamente ocurra el hecho.
        </Paragraph>
        <Paragraph>
          b) Hacerse responsable de todos los cargos que resulten de la pérdida,
          robo o hurto de <Bold>TARJETA PREPAGADA</Bold>, de no haber contratado un seguro
          contra fraude.
        </Paragraph>
        <Paragraph>
          c) Hacerse responsable del pago de la suma acordada como deducible del
          seguro contra fraude, si ha sido contratado.
        </Paragraph>
        <Paragraph>
          d) Presentar reclamo por escrito de las transacciones registradas en
          su cuenta producto de la pérdida, robo o hurto, en caso de haber
          contratado el seguro contra fraude, a fin de que se presente el
          reclamo correspondiente a la Compañía Aseguradora.d) Presentar reclamo
          por escrito de las transacciones registradas en su cuenta producto de
          la pérdida, robo o hurto, en caso de haber contratado el seguro contra
          fraude, a fin de que se presente el reclamo correspondiente a la
          Compañía Aseguradora.
        </Paragraph>
      </Paragraph>
      <Paragraph>
        <Bold>FËANOR</Bold> podrá emitir una nueva <Bold>TARJETA PREPAGADA</Bold> en caso de pérdida, robo
        o hurto, sin que lo anterior signifique una variación o alteración de
        los términos y condiciones del presente CONTRATO, asumiendo EL
        <Bold>TARJETAHABIENTE</Bold> el pago de la comisión por emisión de la nueva tarjeta.
      </Paragraph>

      <Subtitle>DURACIÓN DEL CONTRATO:</Subtitle>
      <Paragraph>
        La vigencia del presente Contrato es de UN (1) año a partir de la
        emisión de la <Bold>TARJETA PREPAGADA</Bold>. No obstante, dicho término se
        prorrogará automáticamente por períodos sucesivos de UN (1) año cada
        uno, a menos que una de las partes le notifique a la otra con por lo
        menos treinta (30) días calendario de anticipación, al vencimiento del
        período original o de una de sus prórrogas, según se trate, su deseo de
        que no se produzca la prórroga correspondiente. En este sentido, EL
        <Bold>TARJETAHABIENTE</Bold> reconoce y acepta que <Bold>FËANOR</Bold>, por ser el propietario
        único del plástico y por políticas internas, podrá rescindir el presente
        contrato en cualquier momento, previa Notificación por escrito.
      </Paragraph>

      <Subtitle>CAUSALES DE RESOLUCIÓN O TERMINACIÓN DEL CONTRATO:</Subtitle>
      <Paragraph>
        Sin perjuicio de lo pactado en este Contrato, queda entendido y
        convenido que <Bold>FËANOR</Bold> podrá declarar de plazo vencido y exigir el pago
        total de la suma adeudada por <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold>, y a la vez dar por
        terminado el presente Contrato de inmediato, comunicando por escrito, en
        caso de que ocurra alguno de los siguientes eventos:
        <Paragraph>
          a) Que <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> sea secuestrado, embargado, o declarado en
          quiebra o en concurso de acreedores.
        </Paragraph>
        <Paragraph>
          b) Si <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> no mantiene de forma regular fondos
          suficientes disponibles en la CUENTA para cubrir las comisiones,
          cargos, impuestos y demás cobros que se deban realizar sobre la
          <Bold>TARJETA PREPAGADA</Bold> de acuerdo con este Contrato, y a lo establecido en
          el tarifario.
        </Paragraph>
        <Paragraph>
          c) Si <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> incumpliere cualquiera de los términos y
          condiciones del presente Contrato, la Ley o cualquier arreglo
          convenido con <Bold>FËANOR</Bold>.
        </Paragraph>
        <Paragraph>
          d) Si <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> se viera involucrado en algún hecho, o
          circunstancias relacionadas con blanqueo de capitales, u operaciones
          ilícitas o eventos que contravengan las leyes vigentes.
        </Paragraph>
        <Paragraph>
          e) Si se comprueba que <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> ha presentado documentos o
          información falsa o irregular para la adquisición de la TARJETA
          PREPAGADA u otra obligación, facilidad de crédito o producto ofrecido
          por <Bold>FËANOR</Bold>.
        </Paragraph>
        <Paragraph>
          f) Cuando haya indicio de fraude o cualquier perjuicio en contra de
          <Bold>FËANOR</Bold> por parte del <Bold>TARJETAHABIENTE</Bold> o EL (LOS) <Bold>TARJETAHABIENTE</Bold> (S)
          ADICIONAL (ES).
        </Paragraph>
        <Paragraph>
          g) El incumplimiento del <Bold>TARJETAHABIENTE</Bold> de cualquier de las
          obligaciones que emanan del presente Contrato.
        </Paragraph>
        <Paragraph>
          h) Si <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> mantiene otras obligaciones morosas con
          <Bold>FËANOR</Bold>.
        </Paragraph>
        <Paragraph>i) Por la muerte del <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold>.</Paragraph>
        <Paragraph>
          j) Si <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> se niega a aceptar o rechaza las
          modificaciones que <Bold>FËANOR</Bold> realice al presente Contrato.
        </Paragraph>
        <Paragraph>
          k) Por cualquier causa que, a juicio de <Bold>FËANOR</Bold>, haga necesaria la
          cancelación de la <Bold>TARJETA PREPAGADA</Bold>.
        </Paragraph>
      </Paragraph>
      <Paragraph>
        La terminación de este Contrato por alguna de las causales enumeradas en
        este punto suspende de inmediato el derecho a utilizar la TARJETA
        PREPAGADA.
      </Paragraph>

      <Subtitle>DECLARACIÓN DE LEGITIMIDAD DE <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold>:</Subtitle>
      <Paragraph>
        FEÄNOR ha implementado políticas y procedimientos para prevención de
        blanqueo de capital, financiamiento de terrorismo y proliferación de
        armas de destrucción masiva, las cuales son de obligatorio cumplimiento
        para <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold>.
      </Paragraph>
      <Paragraph>
        <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> certifica que los fondos en este Contrato son
        producto de actividades comerciales lícitas. Adicionalmente, declara EL
        CLIENTE que conoce y se hace responsable del origen legal de los fondos
        con los cuales realizará transacciones con <Bold>FËANOR</Bold>. Si <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold>
        llegare a ser: (a) vinculado, por parte de las autoridades nacionales o
        internacionales competentes, a cualquier tipo de investigación por
        delitos relacionados con drogas, tales como pero sin limitarse a tráfico
        de drogas, narcotráfico, terrorismo, secuestro, lavado de activos,
        financiación del terrorismo y/o administración de recursos relacionados
        con actividades terroristas u otros delitos relacionados con el lavado
        de activos y financiación del terrorismo; (b) incluidos en listas para
        el control de lavado de activos y financiación del terrorismo
        administradas por cualquier autoridad nacional o extranjera, tales como
        la lista de la Oficina de Control de Activos en el Exterior (OFAC)
        emitida por la Oficina del Tesoro de los Estados Unidos de América, la
        lista de la Organización de las Naciones Unidas y otras listas públicas
        relacionadas con el tema del lavado de activos y financiación del
        terrorismo; o (c) condenados por parte de las autoridades nacionales o
        internacionales competentes en cualquier tipo de proceso judicial
        relacionado con la comisión de los anteriores delitos en cualquier
        jurisdicción, se entenderá que <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> ha incumplido el
        presente contrato.
      </Paragraph>
      <Paragraph>
        También se entenderá que <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> ha incumplido este Contrato
        si cualquiera de sus representantes legales se vincula a cualquier
        investigación por parte de las autoridades nacionales o internacionales
        competentes, o se comprueba mediante fallo judicial que han incurrido en
        algunas de las conductas tipificadas como delitos de Blanqueo de
        Capitales en el Código Penal.
      </Paragraph>
      <Paragraph>
        Igualmente, <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> declara que conoce y está en cumplimiento
        con, no ha previamente infringido, y no ha sido acusado de, ni condenado
        por, haber violado la Ley 23 de 27 de abril de 2015, que adopta medidas
        para prevenir el blanqueo de capitales, el financiamiento del terrorismo
        y el financiamiento de la proliferación de armas de destrucción masiva y
        dicta otras disposiciones, al igual que el Decreto Ejecutivo 35 de 8 de
        septiembre de 2022, por medio del cual se reglamenta la Ley 23 de 27 de
        abril de 2015 y cualquier modificación futura.
      </Paragraph>
      <Paragraph>
        El incumplimiento por parte de <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold>, de acuerdo con lo
        estipulado en esta cláusula, dará lugar a que <Bold>FËANOR</Bold> declare este
        Contrato de resuelto de pleno derecho sin necesidad de previa
        declaración judicial de autoridad competente.
      </Paragraph>

      <Subtitle>CESIÓN:</Subtitle>
      <Paragraph>
        El <Bold>TARJETAHABIENTE</Bold> por este medio acepta que <Bold>FËANOR</Bold> podrá, sin necesidad
        de previo aviso o notificación a <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold>, ceder, negociar,
        vender o traspasar de forma total o parcial, cualquiera de los créditos
        derivados del presente CONTRATO, así como los demás derechos que derivan
        del mismo, sin necesidad de dar aviso a <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> ni de obtener
        su aceptación.
      </Paragraph>

      <Paragraph>
        <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> autoriza a <Bold>FËANOR</Bold>, sus subsidiarias y/o afiliadas,
        cesionarios o sucesoras, así como cualquier compañía que, por una
        operación de cesión, administración o compra de cartera adquiera los
        derechos de mis cuentas y/o créditos a realizar consultas de mis datos
        personales en el Servicio de Verificación de Identidad del Tribunal
        Electoral cuando <Bold>FËANOR</Bold> lo estime necesario.
      </Paragraph>
      <Paragraph>
        <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> exonera a <Bold>FËANOR</Bold> de cualquier consecuencia o
        responsabilidad resultante del ejercicio de solicitar o suministrar
        información, o por razón de cualesquiera autorizaciones contenidas en la
        presente carta, a <Bold>FËANOR</Bold>, a sus compañías afiliadas, subsidiarias,
        cesionarios y/o sucesoras, a sus empleados, ejecutivos, directores
        dignatarios o apoderados, así como cualquier compañía que por una
        operación de cesión, administración o compra de cartera adquiera los
        derechos de mis cuentas y/o créditos.
      </Paragraph>
      <Paragraph>
        Queda entendido que <Bold>FËANOR</Bold> suministrará a requerimiento del interesado
        toda la información crediticia recopilada en base a esta autorización.
      </Paragraph>
      <Paragraph>
        Esta autorización se mantendrá vigente para que <Bold>FËANOR</Bold> la ejerza tantas
        veces sea necesario, durante todo el tiempo que éste mantenga vigente
        con <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> la relación objeto del presente CONTRATO.
      </Paragraph>

      <Subtitle>SALDO CORRECTO Y VERDADERO:</Subtitle>
      <Paragraph>
        Para todos los efectos legales, incluyendo la ejecución por <Bold>FËANOR</Bold>, EL
        <Bold>TARJETAHABIENTE</Bold> conviene que se tendrá como saldo correcto y verdadero
        de las obligaciones a cargo <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> el que conste en los
        libros de <Bold>FËANOR</Bold>, según la propia declaración de éste, por lo que la
        certificación que expida <Bold>FËANOR</Bold> en cuanto al importe y exigibilidad del
        saldo deudor una vez revisada o certificada por un Contador Público
        Autorizado, hará plena fe en juicio y prestará mérito ejecutivo
        teniéndose por clara, líquida y exigible la suma expresada en dicha
        certificación.
      </Paragraph>
      <Paragraph>
        <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> renuncia al domicilio y a los trámites del juicio
        ejecutivo, en el caso que <Bold>FËANOR</Bold> tuviere necesidad de recurrir a los
        tribunales de justicia para el cobro de este crédito.
      </Paragraph>

      <Subtitle>MONEDA:</Subtitle>
      <Paragraph>
        Para todos los efectos de este Contrato, todos los pagos, cargos y
        recargos aquí contemplados, se entienden establecidos en dólares
        americanos, moneda de curso legal de los Estados Unidos de América y de
        uso legal en la República de Panamá.
      </Paragraph>

      <Subtitle>TRANSACCIONES EN MONEDA EXTRANJERA:</Subtitle>
      <Paragraph>
        <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> <Bold>TITULAR</Bold> y EL (LOS) <Bold>TARJETAHABIENTE</Bold>(S) ADICIONAL (ES)
        aceptan que al utilizar la Tarjeta para adquirir bienes o servicios que
        sean facturados en moneda diferente al dólar, <Bold>FËANOR</Bold> realizará la
        conversión a dólares conforme a lo que establezca la tasa de cambio que
        mantenga <Bold>VISA INTERNACIONAL</Bold>, según corresponda, al momento en que se
        procese la transacción para su intercambio monetario y su respectiva
        liquidación internacional.
      </Paragraph>
      <Paragraph>
        Al mismo tiempo <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> <Bold>TITULAR</Bold> y EL (LOS) <Bold>TARJETAHABIENTE</Bold>(S)
        ADICIONAL(ES) aceptan que <Bold>FËANOR</Bold> cobrará una comisión por conversión en
        moneda extranjera, sobre el monto de la transacción efectuada en moneda
        extranjera. <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> <Bold>TITULAR</Bold> y EL (LOS) <Bold>TARJETAHABIENTE</Bold>(S)
        ADICIONAL(ES) aceptan que esta comisión podrá ser variada de tiempo en
        tiempo, según lo disponga <Bold>FËANOR</Bold>, la cual se verá reflejada en el
        Tarifario, disponible en LA PLATAFORMA, tabla que EL (LOS)
        <Bold>TARJETAHABIENTE</Bold>(S) se obligan a revisar y aceptar de antemano antes de
        realizar cualquier transacción bajo este concepto.
      </Paragraph>

      <Subtitle>AVISOS Y NOTIFICACIONES POR PARTE DE <Bold>FËANOR</Bold>:</Subtitle>
      <Paragraph>
        Cualquier aviso o notificación que <Bold>FËANOR</Bold> deba o desee enviarle a EL
        <Bold>TARJETAHABIENTE</Bold> conforme al presente CONTRATO, lo hará por elección de
        <Bold>FËANOR</Bold> a través de cualquiera de los siguientes medios: (1) LA CUENTA
        que mantiene <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> en LA PLATAFORMA; (2) mediante correo
        electrónico enviado a la dirección electrónica que <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold>
        hubiese provisto a <Bold>FËANOR</Bold>; o (3) por cualquier otro medio que <Bold>FËANOR</Bold>
        considere apropiado.
      </Paragraph>
      <Paragraph>
        Las notificaciones, avisos y cartas despachadas se considerarán para
        todos los efectos legales y debidamente enviadas a <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold>
        siempre que hayan sido remitidas a la última dirección de EL
        <Bold>TARJETAHABIENTE</Bold> registrada en <Bold>FËANOR</Bold>.
      </Paragraph>
      <Paragraph>
        <Bold>EL <Bold>TARJETAHABIENTE</Bold></Bold> declara que todos los datos de dirección, teléfonos y
        datos personales contenidos en la Solicitud de la <Bold>TARJETA PREPAGADA</Bold>, son
        verdaderos y se compromete a informar a <Bold>FËANOR</Bold> por correo electrónico o
        a través de LA CUENTA en LA PLATAFORMA, de cualquier cambio o
        modificación que ocurra en la información suministrada para la
        celebración del presente CONTRATO y la emisión de la(s) TARJETA (S)
        PREPAGADA (S), tales como: cambio de nombre, domicilio, dirección
        postal, dirección electrónica, teléfonos (incluyendo celular), cambio de
        estatus civil, entre otros.
      </Paragraph>

      <Subtitle>MODIFICACIONES AL CONTRATO:</Subtitle>
      <Paragraph>
        Queda convenido que <Bold>FËANOR</Bold> se reserva el derecho y podrá aumentar o
        disminuir cargos y comisiones, las cuales comunicará en LA PLATAFORMA.
      </Paragraph>

      
    </div>
  );
};

export default PlatformContract;
