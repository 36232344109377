import styled from "styled-components";
export const Center = styled.h5`
  text-align: center;
  font-size: 16px;
`;

export const Subtitle = styled.h6`
  text-align: left;
  font-size: 14px;
`;

export const Bold = styled.span`
  font-weight: 500;
`;

export const Paragraph = styled.p`
  margin: 10px 10px;
  font-size: 14px;
  font-family: Montserrat, sans-serif;
`;

export const Underline = styled.span`
  text-decoration: underline;
`;
export default {
  Center,
  Bold,
  Paragraph,
  Subtitle,
  Underline,
};
