import styled from "styled-components";
import colors from "styles/common/colors.js";
import bgCurrencyPrice from "./../../media/bg/yellow-arrows-bg.jpg";

export const CurrencyPriceContainer = styled.div`
  height: 117px;
  /* margin-top: 80px; */
  /* margin-bottom: 80px; */
  background-image: url(${bgCurrencyPrice});
  background-repeat: no-repeat;
  /* background-position: 100px 0; */
  background-size: 100% 100%;
`;
