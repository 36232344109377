import React from "react";
import { Paragraph, Center, Subtitle, Bold, Underline } from "./styles";

const BuyActivesContract = () => {
  return (
    <div
      style={{
        color: "black",
      }}
      id="compraventa"
    >
      <Center>
        <Underline>CONTRATO DE COMPRAVENTA DE ACTIVOS DIGITALES</Underline>
      </Center>
      <Paragraph>Entre los suscritos, a saber:</Paragraph>
      <Paragraph>
        <Bold> FEÄNOR CORPORATION, S.A., </Bold> sociedad anónima debidamente
        inscrita al Folio No. 155706843, de la Sección de Mercantil del Registro
        Público de la República de Panamá, con domicilio en Torres Las América,
        Torre A, oficina 802, Punta Pacífica, Ciudad de Panamá, República de
        Panamá, empresa que provee una plataforma de servicios para facilitar
        transacciones de activos digitales a nivel global, por sí o a través de
        terceros (en adelante, el "<Bold> FËANOR </Bold>"), por una parte y, por
        la otra,
      </Paragraph>
      <Paragraph>
        <Bold> EL CLIENTE </Bold>, es una persona natural o jurídica, que ha
        cumplido con la debida diligencia requerida por <Bold> FËANOR </Bold>y,
        voluntariamente, acuerda celebrar este Contrato de Compraventa de
        Activos Digitales con FËANOR, sujeto a las siguientes
      </Paragraph>

      <Center>A. INTRODUCCIÓN</Center>
      <Subtitle>1. RIESGOS DE LOS ACTIVOS DIGITALES </Subtitle>
      <Paragraph>
        Los activos digitales, criptomonedas, tokens y/o activos digitales (en
        adelante, "activos digitales") no son monedas de curso legal y no están
        respaldadas por un gobierno, por lo tanto, están sujetas a mayores
        riesgos. Estos riesgos incluyen (pero no se limitan a) los siguientes:
        <Paragraph>
          i. la legislación y la regulación (o la ausencia de las mismas)
          criptomonedas o intercambios de criptomonedas pueden cambiar en
          cualquier momento, lo que puede afectar negativamente el uso, la
          transferencia, el intercambio y/o el valor de criptomonedas;
        </Paragraph>
        <Paragraph>
          ii. una vez ejecutada, una transacción de compra o venta de
          criptomonedas puede ser irreversible y, en consecuencia, las pérdidas
          debidas a transacciones fraudulentas o accidentales pueden no ser
          recuperables;
        </Paragraph>
        <Paragraph>
          iii. algunas transacciones de compra o venta criptomonedas se
          considerarán realizadas cuando se registren en un libro público
          (public ledger), como, por ejemplo, en un blockchain, que no es
          necesariamente la fecha o la hora en que el cliente inicia la
          transacción;
        </Paragraph>
        <Paragraph>
          iv. el valor de los activos digitales puede derivarse de la voluntad
          continua de los participantes del mercado de negociar dinero fiat (es
          decir, moneda de curso legal) por una criptomoneda, lo que puede
          resultar en la pérdida total y permanente del valor de una
          criptomoneda en particular si el mercado de criptomonedas colapsa;
        </Paragraph>
        <Paragraph>
          v. no hay garantía de que una persona que acepte una criptomoneda como
          pago hoy continúe haciéndolo en el futuro;
        </Paragraph>
        <Paragraph>
          vi. la volatilidad y la imprevisibilidad del precio de la criptomoneda
          en relación con la moneda fiduciaria pueden generar pérdidas
          significativas en un corto período de tiempo;
        </Paragraph>
        <Paragraph>
          vii. el valor de una criptomoneda en particular puede caer en
          cualquier momento si, por ejemplo, se crea una nueva y mejor
          criptomoneda o si los desarrolladores de software realizan cambios
          inesperados en el funcionamiento de la criptomoneda;
        </Paragraph>
        <Paragraph>
          viii. como la criptomoneda es una moneda digital y, por lo tanto,
          intangible, esto significa que, como cualquier otro sistema digital,
          los activos digitales corren el riesgo de sufrir fraudes, ataques
          cibernéticos y verse afectadas por problemas o dificultades técnicas
          que podrían provocar la pérdida de sus criptoactivos o retrasar o
          impedir su capacidad para acceder a ellos o utilizarlos; y
        </Paragraph>
        <Paragraph>
          1.2 <Bold> FËANOR </Bold> NO HACE NINGUNA REPRESENTACIÓN CON RESPECTO
          A LA POSIBILIDAD O PROBABILIDAD DE QUE CUALQUIER INTERCAMBIO REAL O
          PROPUESTO EN EL INTERCAMBIO LOGRARÁ DE HECHO UN RESULTADO U OBJETIVO
          PARTICULAR. EL RENDIMIENTO PASADO NO ES UNA GARANTÍA DE ÉXITO FUTURO
          Y, DE HECHO, LA VOLATILIDAD SIGNIFICA QUE LAS RENTABILIDADES EN
          CUALQUIER PERÍODO PUEDEN SER MUY SUPERIORES O INFERIORES A LOS DEL
          PERÍODO ANTERIOR.
        </Paragraph>
      </Paragraph>
      <Center>B. CLÁUSULAS OPERATIVAS</Center>
      <Paragraph>
        <Underline> PRIMERA (OBJETO):</Underline> <Bold> EL CLIENTE </Bold>{" "}
        encarga a FËANOR, y éste acepta el encargo, la compra y venta de
        criptomonedas, tokens y/o activos digitales (en adelante, el
        "criptomonedas"), a nombre de EL CLIENTE, a través de la plataforma de{" "}
        <Bold> FËANOR </Bold> (en adelante, el "la Plataforma") o cualquier otro
        medio previamente autorizado por <Bold> FËANOR </Bold>.
      </Paragraph>
      <Paragraph>
        <Bold> EL CLIENTE </Bold> autoriza a <Bold> FËANOR </Bold>a compartir
        toda la información relativa a este contrato y sus respectivos
        servicios, incluyendo, sin limitar, información relativa a la debida
        diligencia de
        <Bold> EL CLIENTE </Bold>, para proceder con el encargo de compra y
        venta de criptomonedas.
      </Paragraph>
      <Paragraph>
        <Underline> SEGUNDA (INSTRUCCIONES):</Underline>{" "}
        <Bold> EL CLIENTE </Bold> enviará a<Bold> FËANOR </Bold>las
        instrucciones sobre la compra y venta de los activos digitales a través
        de la Plataforma.
      </Paragraph>
      <Paragraph>
        <Bold> EL CLIENTE </Bold> acepta que toda instrucción recibida por{" "}
        <Bold> FËANOR </Bold>, a través de la Plataforma o cualquier otro medio
        previamente autorizado por FEÄNOR, es válida, ejecutable y correcta,
        identifican a <Bold> EL CLIENTE </Bold> y tienen igual validez de
        instrucción con firma manuscrita en original, por lo tanto, acepta
        indemnizar y mantener a FËANOR, sus accionistas, directores, dignatarios
        y empleados, libres de cualesquiera y todas las responsabilidades,
        pérdidas, daños, costos y gastos que resulten del cumplimiento de dichas
        instrucciones.
      </Paragraph>
      <Paragraph>
        <Bold> EL CLIENTE </Bold> podrá dar instrucciones verbales de compra de
        criptomonedas a<Bold> FËANOR </Bold>fuera de la Plataforma, previa
        autorización de FËANOR, obligándose a confirmar por escrito y de
        inmediato, todas y cualquiera instrucción verbal; sin embargo, la falta
        de confirmación y los eventos que posteriormente se generen por esa
        falta de confirmación son responsabilidad exclusiva de{" "}
        <Bold> EL CLIENTE </Bold>.
      </Paragraph>
      <Paragraph>
        Se presumen ciertas y correctas las afirmaciones de{" "}
        <Bold> FËANOR </Bold>sobre el recibo de instrucciones verbales, quedando
        exonerado de responsabilidad por la ejecución o cumplimiento de tales
        instrucciones. <Bold> FËANOR </Bold>se reserva el derecho a solicitar a
        <Bold> EL CLIENTE </Bold> confirmaciones por escrito, antes de ejecutar
        instrucciones recibidas telefónicamente, por medios electrónicos o
        cualquier otro medio.
      </Paragraph>
      <Paragraph>
        <Underline> TERCERA (TRANSFERENCIA): </Underline>
        <Bold> EL CLIENTE </Bold> transferirá el valor de los activos digitales
        a la billetera digital que mantiene en la Plataforma. Esta transacción
        debe ser originada desde la billetera digital de propiedad{" "}
        <Bold> EL CLIENTE </Bold> a la billetera digital que mantiene en la
        Plataforma. <Bold> FËANOR </Bold>se reserva el derecho de terminar este
        contrato en el evento que <Bold> EL CLIENTE </Bold>
        utilice una billetera digital de terceros sin autorización previa de{" "}
        <Bold> FËANOR </Bold>o en caso de que la billetera hay sido utilizada
        para operaciones irregulares que a juicio de <Bold> FËANOR </Bold>.{" "}
        <br />
        <Bold> EL CLIENTE </Bold> proporcionará a <Bold> FËANOR </Bold>cualquier
        información relevante necesaria para la custodia adecuada de los activos
        digitales, incluyendo actualizaciones sobre cambios en la titularidad o
        instrucciones específicas de manejo.
      </Paragraph>
      <Paragraph>
        <Underline> CUARTA (COMPRAVENTA):</Underline> <Bold> FËANOR </Bold>
        enviará el producto de la compraventa de los activos digitales a la
        billetera digital que mantiene <Bold> EL CLIENTE </Bold> mantiene en la
        Plataforma. <br />
        En el evento que <Bold> EL CLIENTE </Bold> solicite el cambio de los
        activos digitales a dinero fiduciario, deberá remitirle una cuenta
        bancaria a nombre de <Bold> EL CLIENTE </Bold>, la cual debe ser
        provista en las Instrucciones de Transferencia. Las transferencias a
        nombre de terceros no serán procesadas por <Bold> FËANOR </Bold>.
      </Paragraph>
      <Paragraph>
        <Underline> QUINTA (COMISIÓN): </Underline>
        <Bold> FËANOR </Bold>cobrará a <Bold> EL CLIENTE </Bold> una comisión
        por cada transacción que realice en la Plataforma o cualquier otro medio
        previamente autorizado por <Bold> FËANOR </Bold>. La comisión para pagar
        por <Bold> EL CLIENTE </Bold>
        será generada y divulgada por medio de la Plataforma o cualquier otro
        medio previamente autorizado por FEÄNOR al momento de requerir el
        servicio y consta como Anexo No. 1 del Contrato.
      </Paragraph>
      <Paragraph>
        <Underline> SEXTA (CUSTODIA):</Underline> <Bold> EL CLIENTE </Bold>{" "}
        podrá utilizar los servicios de custodia de criptomonedas en la
        billetera que mantiene en la Plataforma.
      </Paragraph>
      <Paragraph>
        <Underline> SÉPTIMA (BILLETERA DIGITAL): </Underline>
        <Bold> EL CLIENTE </Bold> podrá transferir sus criptomonedas de su
        billetera digital en la Plataforma a su billetera digital personal fuera
        de la Plataforma. <Bold> EL CLIENTE </Bold> es responsable del resultado
        de este tipo de transferencias, por tanto, cualquier pérdida por error o
        negligencia al momento de realizar la transferencia es de su exclusiva
        responsabilidad.
      </Paragraph>
      <Paragraph>
        <Bold> EL CLIENTE </Bold> podrá solicitar a <Bold> FËANOR </Bold>le sean
        transferidos sus criptomonedas a una billetera digital de su pertenencia
        fuera de la Plataforma, declarando bajo la gravedad del juramente que
        esta billetera digital es de su propiedad.
      </Paragraph>
      <Paragraph>
        <Underline> OCTAVA (TERMINACIÓN DEL CONTRATO: </Underline>: Declaran{" "}
        <Bold> LAS PARTES </Bold> que podrán terminar el contrato de la
        siguiente forma:
        <Paragraph>
          a) <Bold> EL CLIENTE </Bold> podrá terminar el contrato mediante
          notificación con preaviso con por lo menos 15 días de anticipación a
          través de la Plataforma o correo electrónico.
        </Paragraph>
        <Paragraph>
          b) De forma inmediata cuando, a juicio de <Bold> FËANOR </Bold>y a su
          sola discreción, a través de la Plataforma o correo electrónico.
        </Paragraph>
        <Paragraph>
          c) En caso de que <Bold> FËANOR </Bold>no pueda verificar, validar y/o
          actualizar la información suministrada por <Bold> EL CLIENTE </Bold>.
        </Paragraph>
        <Paragraph>
          d) De forma inmediata cuando <Bold> EL CLIENTE </Bold> está
          involucrado en cualquier investigación o actividad ilícita o delictiva
          o delictiva.
        </Paragraph>
        <Paragraph>
          e) En caso de que <Bold> EL CLIENTE </Bold> no mantenga criptomonedas
          en su billetera digital en la Plataforma y no haya accedido a su
          cuenta por más de seis (6) meses.
        </Paragraph>
        <Paragraph>
          f) Por cualquier otro motivo, por decisión unilateral de{" "}
          <Bold> FËANOR </Bold>y a su sola discreción.
        </Paragraph>
      </Paragraph>
      <Paragraph>
        Al darse la terminación de este Acuerdo, <Bold> FËANOR </Bold>tendrá
        derecho a recibir todos los cargos que le correspondan por los servicios
        prestados hasta el momento de la terminación del Contrato.
      </Paragraph>

      <Center>C. CLÁUSULAS MISCELÁNEAS</Center>
      <Paragraph>
        <Underline> PRIMERA (LIMITACIÓN DE LA RESPONSABILIDAD):</Underline>
        <Bold> FËANOR </Bold> no será responsable por cualquier pérdida o daño
        que <Bold> EL CLIENTE </Bold>
        pueda sufrir causados por restricciones gubernamentales, suspensión del
        comercio, guerra, pandemia, huelgas, demoras, apagones, o falla en las
        transmisiones o comunicaciones de cualquier tipo, o por cualesquiera
        eventos, acciones u omisiones fuera del control de <Bold> FËANOR </Bold>
        .
      </Paragraph>
      <Paragraph>
        <Bold> FËANOR </Bold> no serán responsable por eventualidades causadas
        por reguladores o demoras en transferencias de los activos digitales o
        dinero fiat (por parte de los bancos), fuerza mayor, caso fortuito o
        eventos significativos fuera de su control. FEÄNOR actuará de la forma
        más rápida posible para realizar las transacciones, pero existen
        situaciones de congestiones de red y demora en transferencias bancarias
        que pueden retrasar operaciones, que <Bold> EL CLIENTE </Bold> reconoce,
        acepta y entiende.
      </Paragraph>
      <Paragraph>
        <Bold> EL CLIENTE </Bold> renuncia a cualquier reclamo o acción legal
        por responsabilidad contra <Bold> FËANOR </Bold> o contra sus
        accionistas, directores, dignatarios o empleados, por hechos
        relacionados a la administración de sus criptomonedas, salvo en el caso
        de dolo.
      </Paragraph>
      <Paragraph>
        <Underline> SEGUNDA (SOBRE LOS ACTIVOS DIGITALES): </Underline>{" "}
        <Bold> EL CLIENTE </Bold> entiende y reconoce que los activos digitales
        son consideradas activos volátiles. FEÄNOR no será responsable por
        fluctuaciones en el valor de los activos digitales al utilizar la
        Plataforma o cualquier otro medio previamente autorizado por{" "}
        <Bold> FËANOR </Bold> o la billetera digital que
        <Bold> EL CLIENTE </Bold> mantiene en la Plataforma, así como tampoco
        puede garantizar precios fijos de criptomonedas al momento de realizar
        la compraventa.
      </Paragraph>
      <Paragraph>
        <Bold> EL CLIENTE </Bold> reconoce que tener garantía de ganancias o
        exoneración de pérdidas es algo imposible en el mercado de los activos
        digitales y cualquier otro mercado financiero. <Bold> EL CLIENTE </Bold>{" "}
        reconoce que no ha recibido tales garantías de parte de FEÄNOR, sus
        accionistas, directores, dignatarios y empleados y que no ha celebrado
        este Acuerdo en consideración a tales garantías.
      </Paragraph>
      <Paragraph>
        <Underline>
          TERCERA (DECLARACIÓN DE <Bold> EL CLIENTE </Bold>):
        </Underline>
        <Bold> FËANOR </Bold> ha implementado políticas y procedimientos para
        prevención de blanqueo de capital, financiamiento de terrorismo y
        proliferación de armas de destrucción masiva, las cuales son de
        obligatorio cumplimiento para <Bold> EL CLIENTE </Bold>.
      </Paragraph>
      <Paragraph>
        <Bold> EL CLIENTE </Bold> certifica que los fondos en este Contrato de
        Compraventa son producto de actividades comerciales lícitas.
        Adicionalmente, declara <Bold> EL CLIENTE </Bold> que conoce y se hace
        responsable del origen legal de los fondos con los cuales realizará
        transacciones con <Bold> FËANOR </Bold>. Si <Bold> EL CLIENTE </Bold>
        llegare a ser: (a) vinculado, por parte de las autoridades nacionales o
        internacionales competentes, a cualquier tipo de investigación por
        delitos relacionados con drogas, tales como pero sin limitarse a tráfico
        de drogas, narcotráfico, terrorismo, secuestro, lavado de activos,
        financiación del terrorismo y/o administración de recursos relacionados
        con actividades terroristas u otros delitos relacionados con el lavado
        de activos y financiación del terrorismo; (b) incluidos en listas para
        el control de lavado de activos y financiación del terrorismo
        administradas por cualquier autoridad nacional o extranjera, tales como
        la lista de la Oficina de Control de Activos en el Exterior (OFAC)
        emitida por la Oficina del Tesoro de los Estados Unidos de América, la
        lista de la Organización de las Naciones Unidas y otras listas públicas
        relacionadas con el tema del lavado de activos y financiación del
        terrorismo; o (c) condenados por parte de las autoridades nacionales o
        internacionales competentes en cualquier tipo de proceso judicial
        relacionado con la comisión de los anteriores delitos en cualquier
        jurisdicción, se entenderá que <Bold> EL CLIENTE </Bold> ha incumplido
        el presente Contrato de Compraventa.
      </Paragraph>
      <Paragraph>
        También se entenderá que <Bold> EL CLIENTE </Bold> ha incumplido este
        Contrato de Compraventa si cualquiera de sus representantes legales se
        vincula a cualquier investigación por parte de las autoridades
        nacionales o internacionales competentes, o se comprueba mediante fallo
        judicial que han incurrido en algunas de las conductas tipificadas como
        delitos de Blanqueo de Capitales en el Código Penal.
      </Paragraph>
      <Paragraph>
        Igualmente, <Bold> EL CLIENTE </Bold> declara que conoce y está en
        cumplimiento con, no ha previamente infringido, y no ha sido acusado de,
        ni condenado por, haber violado la Ley 23 de 27 de abril de 2015, que
        adopta medidas para prevenir el blanqueo de capitales, el financiamiento
        del terrorismo y el financiamiento de la proliferación de armas de
        destrucción masiva y dicta otras disposiciones, al igual que el Decreto
        Ejecutivo 35 de 8 de septiembre de 2022, por medio del cual se
        reglamenta la Ley 23 de 27 de abril de 2015 y cualquier modificación
        futura.
      </Paragraph>
      <Paragraph>
        El incumplimiento por parte de <Bold> EL CLIENTE </Bold>, de acuerdo con
        lo estipulado en esta cláusula, dará lugar a que <Bold> FËANOR </Bold>
        declare este Contrato de Compraventa resuelto de pleno derecho sin
        necesidad de previa declaración judicial de autoridad competente.
      </Paragraph>
      <Paragraph>
        <Underline> CUARTA (INFORMACIÓN CONFIDENCIAL): </Underline>Declaran{" "}
        <Bold> LAS PARTES </Bold> que toda información que reciban por razón de
        la celebración, cumplimiento o ejecución de este contrato será
        considerada como información confidencial, en consecuencia, se obligan a
        mantener dicha información confidencial y a no revelar la misma a
        terceras personas sin el previo consentimiento por escrito o verbal de
        la otra parte.
      </Paragraph>
      <Paragraph>
        Se exceptúa del deber de confidencialidad la información cuando provenga
        de autoridades competentes de de conformidad con lo establecido en la
        legislación aplicable a este Contrato.
      </Paragraph>
      <Paragraph>
        Tampoco se considerará como un incumplimiento al deber de
        confidencialidad, la revelación y/ divulgación de información que estén
        obligadas a suministrar y remitir a las autoridades competentes en
        cumplimiento de las disposiciones legales vigentes relativas a la
        prevención del delito de blanqueo de capitales y el financiamiento del
        terrorismo.
      </Paragraph>
      <Paragraph>
        Queda igualmente excluida del deber de confidencialidad toda información
        que, aun habiendo sido recibida bajo tal carácter, por razones no
        imputables a <Bold> LAS PARTES </Bold> se convierta en información
        pública o llegue a estar disponible al público por cualquier otro medio.
      </Paragraph>
      <Paragraph>
        <Underline> QUINTA (PROTECCIÓN DE DATOS):</Underline>
        <Bold> EL CLIENTE </Bold> por este medio da consentimiento libre y
        expreso a <Bold> FËANOR </Bold>para recopilar, tratar, procesar y
        archivar toda la información necesaria, ya sea confidencial y/o
        sensitiva sobre su persona, o producto contratado para que{" "}
        <Bold> FËANOR </Bold> pueda: (i) cumplir con la presente relación
        contractual que aquí se contrata, así como cualquier otra que
        actualmente o en el futuro tenga <Bold> EL CLIENTE </Bold> con{" "}
        <Bold> FËANOR </Bold>y/o subsidiarias o empresas relacionadas al grupo;
        (ii) Realizar procesos de debida diligencia y cumplimiento, para así
        poder cumplir con las regulaciones relativas a las políticas de conocer
        a su cliente actuales y las que en el futuro sean promulgadas; (iii)
        Evaluar otros productos y/o servicios de <Bold> FËANOR </Bold>y/o
        subsidiarias o empresas relacionadas al grupo, que crea que le puedan
        interesar o ser conveniente para a <Bold> EL CLIENTE </Bold>; (iv)
        Enviar comunicaciones de mercadeo y demás información de servicios,
        productos y promociones de <Bold> FËANOR </Bold>y/o subsidiarias o
        empresas relacionadas al grupo; (v) Administrar promociones, encuestas y
        grupos de análisis, publicidad basada en los intereses de{" "}
        <Bold> EL CLIENTE </Bold>, así como realizar investigaciones y análisis
        de
        <Bold> FËANOR </Bold>y/o subsidiarias o empresas relacionadas al grupo;
        y (vi) para cualquier otra actividad que <Bold> EL CLIENTE </Bold> haya
        autorizado previamente a<Bold> FËANOR </Bold>o en el futuro o que la
        legislación vigente en materia de protección de datos privado permita.
      </Paragraph>
      <Paragraph>
        De igual manera <Bold> EL CLIENTE </Bold> autoriza expresamente a{" "}
        <Bold> FËANOR </Bold>, y sin requerir una autorización adicional futura,
        para traspasar y/o divulgar toda la información necesaria, ya sea
        confidencial y/o sensitiva sobre su persona y/o operaciones, a: (i)
        Subsidiarias o empresas relacionadas a <Bold> FËANOR </Bold>; (ii) a las
        autoridades y entes reguladores en la forma prevista por la legislación;
        (iii) a empresas de manejo de listados de historial créditos, agencias
        de Información de datos o sus similares; y (v) empresas asesoras de{" "}
        <Bold> FËANOR </Bold>, o de empresas de su mismo grupo tales como, pero
        sin limitar, sus abogados, contadores, calificadores de riesgo y
        empresas aseguradoras.
      </Paragraph>
      <Paragraph>
        <Bold> EL CLIENTE </Bold> autoriza irrevocablemente a{" "}
        <Bold> FËANOR </Bold>para que solicite y obtenga información y
        documentos relacionados con <Bold> EL CLIENTE </Bold>y sea de oficinas o
        funcionarios gubernamentales, o personas o empresas privadas, tanto
        nacionales como extranjeras. <Bold> FËANOR </Bold>y las personas que
        suministren información quedan relevados de toda responsabilidad por tal
        acción. <Bold> EL CLIENTE </Bold> otorga su consentimiento expreso a
        FËANOR, para que con los datos que le haya proporcionado y/o autorizados
        a obtener de terceros, pueda crear perfiles, evaluaciones, así como para
        tomar decisiones automatizadas sobre dichos perfiles.
      </Paragraph>
      <Paragraph>
        Durante la vigencia del presente contrato, <Bold> EL CLIENTE </Bold>{" "}
        tendrá siempre el derecho a solicitarle a FËANOR, el acceso a su
        información, su corrección y eliminación, de la forma y medios
        establecidos en las políticas de privacidad en materia de protección de
        datos.
      </Paragraph>
      <Paragraph>
        <Underline> SEXTA (JURISDICCIÓN Y COMPETENCIA): </Underline>Declaran LAS
        PARTES que el presente contrato se regirá en su totalidad por las leyes
        de la República de Panamá.
      </Paragraph>
      <Paragraph>
        Acuerdan <Bold> LAS PARTES </Bold> que cualquier controversia,
        discrepancia, litigio, disputa, reclamo o diferencia originada en
        relación con la ejecución, validez existencia, aplicabilidad, nulidad,
        resolución, terminación o interpretación de este acuerdo o de cualquier
        otra materia vinculada o contenida en él, será resuelta definitivamente
        mediante un Arbitraje de Derecho de conformidad con el reglamento de
        CONCILIACIÓN Y ARBITRAJE DEL CENTRO DE ARBITRAJE DE LA CÁMARA DE
        COMERCIO Y AGRICULTURA DE PANAMÁ (CECAP).
      </Paragraph>
      <Paragraph>
        <Bold> LAS PARTES </Bold> contratantes aceptan de manera expresa que el
        laudo arbitral será definitivo y obligatorio, renunciando a interponer
        cualquier medio de impugnación contra el laudo arbitral. El Laudo
        Arbitral determinará la forma en que deben satisfacerse los gastos
        relacionados con el arbitraje.
      </Paragraph>
      <Paragraph>
        La Ley Aplicable a la validez, efectos, así como cualquier otro asunto
        vinculado al arbitraje y a la materia del mismo será la Ley Panameña.
        <Bold> LAS PARTES </Bold> en general, harán todos sus esfuerzos para que
        mantengan vigente el presente compromiso arbitral, respondiendo por los
        perjuicios de su incumplimiento. Asimismo, renuncian a cualquier acción
        o excepción destinada a limitarlo o anularlo.
      </Paragraph>
      <Paragraph>
        <Underline> SEXTA (CONTRATO DE PLATAFORMA):</Underline> Declaran{" "}
        <Bold> LAS PARTES </Bold> que el Contrato de la Plataforma forma parte
        de este contrato.
      </Paragraph>
    </div>
  );
};

export default BuyActivesContract;
