import styled from "styled-components";
import colors from "./common/colors";

export const PrimaryText = styled.span`
  color: rgb(0,0,0,0.7);
  /* color: ${colors.feanor_orange}; */
  font-family: "Bahnschift Light", sans-serif;
  box-sizing: border-box;
  line-height: 1.3;
  @media (max-width: 600px) {
    font-size: 19px;
  }
`;
export const SubPrimaryText = styled(PrimaryText)`
  margin: 0 auto 20px auto;
  color: white;
  padding: 10px;
  @media (max-width: 600px) {
    margin-top: 25px;
    text-align: center;
    font-size: 17px;
  }
`;

export const SecondaryText = styled.span`
  color: ${colors.feanor_gold};
  font-family: "Bahnschift Light";
  box-sizing: border-box;
  @media (max-width: 600px) {
    font-size: 19px;
  }
`; 
