import React, { useState } from "react";
import ContactForm from "components/ContactForm";

const useCotactForm = () => {
  const [openForm, setOpenForm] = useState(false);

  const onCloseForm = () => {
    console.log("cerrar")
    setOpenForm(false);
  };
  
  return [ContactForm, openForm, setOpenForm, onCloseForm];
};

export default useCotactForm;
